import React from 'react';
import IMechanism from '../../../../../interfaces/Mechanism';
import MechanismInfo from '../MechanismInfo/MechanismInfo';
// import MechanismTable from '../MechanismTable/MechanismTable';
import ColorContext from '../../../../../context/ColorContext';
import TargetCompoundList from '../../../TumourTypeDetail/Partials/TargetCompoundList/TargetCompoundList';

interface IProps {
  mechanism: IMechanism, // Mechanism (Associated area)
  active: boolean, // Expanded/collapsed accordion item.
  // eslint-disable-next-line no-unused-vars
  onMechanismClick: (mechanismId: number) => void // Mechanism (accordion) change/click callback.
}

function MechanismItem(props: IProps) {
  /**
   * Handle click event on accordion item.
   */
  function handleClick() {
    const { mechanism, onMechanismClick } = props;
    onMechanismClick(Number(mechanism.id));
  }

  const { mechanism, active } = props;
  const compounds = mechanism.field_targets_compounds;
  compounds.sort((a, b) => (a.label > b.label ? 1 : -1));
  const headerStyle = {
    background: `${mechanism.field_color}70`,
  };
  return (
    <ColorContext.Provider value={mechanism.field_color}>
      <div className="collapse-card">
        <div className="container">
          <button type="button" className={`card-header header-action ${active ? 'active' : ''}`} onClick={handleClick} style={headerStyle}>
            <h2><strong>{ mechanism.label }</strong></h2>
            <em className="collapse-icon" />
          </button>
        </div>
        <div className={`card-body ${active ? 'active' : ''}`}>
          <div className="container">
            <div className="inner-content bg-transparent">
              <MechanismInfo mechanism={mechanism} />
              {/* <MechanismTable mechanism={mechanism} /> */}
              <div className="inner-content bg-transparent">
                <TargetCompoundList
                  mechanisms={compounds}
                  selectedPhase={[]}
                  fromScientificPillar
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ColorContext.Provider>
  );
}

export default MechanismItem;
