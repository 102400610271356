/**
 * Split string and get first word or rest string except first word.
 *
 * @param str
 * @param first
 */
function splitString(str: string, first = true): string {
  const arr = str.split(' ');

  if (first) {
    return arr[0];
  }

  arr.shift();

  return arr ? arr.join(' ') : '';
}

function compareStr(str1: string, str2: string): boolean {
  return str1?.toLowerCase().trim() === str2?.toLowerCase().trim();
}

export { splitString, compareStr };
