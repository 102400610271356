import React, { MouseEvent, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getWindowPathName } from '../../../../../helpers/Common';
import ITargetCompound from '../../../../../interfaces/TargetCompound';
import TargetCompound from '../../../../../store/TargetCompound';
import TumourType from '../../../../../store/TumourType';
import IApp from '../../../../../interfaces/App';
import AppContext from '../../../../../context/AppContext';

interface IProps {
  mechanisms: ITargetCompound[], // Targets/compounds
  selectedPhase : string[],
  fromScientificPillar?: boolean,
}

function TargetCompoundList(props: IProps) {
  const { id } = useParams<{ id: string }>();
  const data: IApp = useContext(AppContext);
  const tumourTypeId = Number(id);
  const tumourType = TumourType.GetById(tumourTypeId);
  const tumourTypes = TumourType.GetAll();
  const { mechanisms, selectedPhase, fromScientificPillar } = props;
  const history = useHistory();

  /**
   * Handle link click.
   *
   * @param tcid
   */
  function handleLink(tcid: number, event: MouseEvent) {
    event.preventDefault();
    let ttid = tumourTypeId;
    if (Number.isNaN(tumourTypeId)) {
      const tumrType = tumourTypes.find((type) => type.field_targets_compounds
        .find((targetCompound) => Number(targetCompound.id) === Number(tcid)));
      ttid = tumrType?.id || -1;
    }

    const url = fromScientificPillar ? `/scientific-pillars/type/${ttid}/target/${tcid}` : `/tumour-type/${ttid}/target-compound/${tcid}`;
    const from = {
      pathname: url,
      state: fromScientificPillar ? { prevPath: getWindowPathName() } : { selectedPhase },
      prevPath: getWindowPathName(),
    };
    history.push(from);
  }

  const isCompoundHide = data?.label === 'Singapore - PROACTIVE' && tumourType?.label === 'Hematologic';
  return (
    <div className="type-action-card type-action-card-pr">
      <ul className="type-sub-card">
        { mechanisms.map((item) => (
          <li key={item.id} style={item.label === 'AKT inhibition' && isCompoundHide ? { display: 'none' } : {}}>
            <noindex>
              <a
                href="#"
                onClick={(event) => handleLink(item.id, event)}
                className="card-block"
                rel="nofollow"
              >
                <div className="card-head">
                  <h3><strong>{ item.label }</strong></h3>
                </div>
                <div className="card-body">
                  <ul className="list-info">
                    <li>
                      <strong>Compound</strong>
                      {' '}
                      <span>{ item.field_compound_name }</span>
                    </li>
                    <li>
                      <strong>Phase</strong>
                      {' '}
                      <span>{ TargetCompound.GetPhase(item) }</span>
                    </li>
                  </ul>
                  { item.field_footnote && item.field_footnote.label && (
                    <div className="card-note">
                      <small>{ item.field_footnote.label }</small>
                    </div>
                  )}
                </div>
              </a>
            </noindex>
          </li>
        )) }
      </ul>
    </div>
  );
}

TargetCompoundList.defaultProps = {
  fromScientificPillar: false,
};

export default TargetCompoundList;
