import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import IDropdownData from '../../../interfaces/IDropdownData';

interface IProps {
  title: string,
  data: IDropdownData[],
  checkedData: string[],
  listStyle: any,
  isDisabled: boolean,
  // eslint-disable-next-line no-unused-vars
  onItemChange: (id: string, event: ChangeEvent) => void
}

function FilterDropdown(props: IProps) {
  const {
    title,
    data,
    checkedData,
    listStyle,
    isDisabled,
    onItemChange,
  } = props;

  const dropDownRef = useRef<HTMLDivElement>(null);
  const [DropDownOpen, setDropDownOpen] = useState(false);

  const handleDropdown = () => {
    setDropDownOpen(!DropDownOpen);
  };

  const getDropdownItemId = (label: string, id: string) => `${label}-${id}`;

  useEffect(() => {
    const closeDropdown = (e: any) => {
      // @ts-ignore
      if (dropDownRef && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
        setDropDownOpen(false);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, []);

  return (
    <div className={`filter-dropdown ${DropDownOpen ? 'open' : ''} ${isDisabled ? 'disabled' : ''}`} ref={dropDownRef}>
      <button
        type="button"
        className={`dropdown-label ${checkedData.length > 0 ? 'has-sel-data' : ''}`}
        onClick={handleDropdown}
      >
        {title}
      </button>
      <div className="dropdown-list" style={listStyle}>
        <div className="dropdown-list-body">
          {data.map((item) => (
            <label
              className="checkbox styled-checkbox"
              htmlFor={getDropdownItemId(title, item.id)}
              key={getDropdownItemId(title, item.id)}
            >
              <input
                type="checkbox"
                name="dropdown-group"
                onChange={(e) => onItemChange(item.id, e)}
                className="check checkbox-custom"
                id={getDropdownItemId(title, item.id)}
                checked={checkedData.findIndex((dt) => dt === item.id) > -1}
              />
              <span className="phase-link">
                {item.value}
              </span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FilterDropdown;
