import React, {
  ChangeEvent, useEffect, useState, useContext,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import TumourType from '../../../store/TumourType';
import Info from './Partials/Info/Info';
import NotFound from '../NotFound/NotFound';
import getSiteName, { getWindowPathName } from '../../../helpers/Common';
import AuiStore from '../../../store/AreaUnderInvestigation';
import AppContext from '../../../context/AppContext';
import IApp from '../../../interfaces/App';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';
import IndicationCTList from './Partials/IndicationCTList/IndicationCTList';
import FilterDropdown from '../../Shared/FilterDropdown/FilterDropdown';

function IndicationDetail() {
  const location = useLocation();
  const { ttid } = useParams<{ ttid: string }>();
  const query = new URLSearchParams(location.search);
  const auiIds = query.get('aui')?.split(',') || [];
  const phases = query.get('phase')?.split(',') || [];
  const stages = query.get('stage')?.split(',') || [];
  const tcIds = query.get('tc')?.split(',') || [];
  const itemName = query.get('name') || '';

  const data: IApp = useContext(AppContext);
  const tumourTypeId = Number(ttid);
  const tumourType = TumourType.GetById(tumourTypeId);

  const tumorAreaUnderInvestigations = TumourType.GetAreaUnderInvestigations(tumourTypeId);
  const allPhases = TumourType.GetAllClinicalTrialPhasesByAUI(tumourType);
  const allStages = TumourType.GetAllClinicalTrialStagesByAUI(tumourType);
  const tumorTargetCompound = TumourType.GetTargetCompoundsByAUI(data, tumourTypeId);

  const [SelectedStages, setSelectedStages] = useState<string[]>(stages);
  const [SelectedPhases, setSelectedPhases] = useState<string[]>(phases);
  const [SelectedAui, setSelectedAui] = useState<string[]>(auiIds);
  const [SelectedCompounds, setSelectedCompounds] = useState<string[]>(tcIds);
  // const [QueryStr, setQueryStr] = useState<string>('');
  const [auiLabel, setAuiLabel] = useState<string>('Areas under investigations');

  const allClinicalTrials = AuiStore.GetClinicalTrials(
    data,
    tumourType,
    SelectedAui,
    SelectedStages,
    SelectedPhases,
    SelectedCompounds,
    Boolean(data.field_respect_ct_ordering),
    false,
    true,
  );

  if (!tumourType) {
    return (<NotFound />);
  }

  const mainStyle = tumourType.field_visual ? { backgroundImage: `url(${tumourType.field_visual})` } : {};

  const changeAuiLabel = () => {
    let label = '';
    if (SelectedAui.length === 1) {
      tumorAreaUnderInvestigations.forEach((aui) => {
        if (Number(aui.id) === Number(SelectedAui[0])) {
          label = aui.label;
        }
      });
    }
    setAuiLabel(label);
  };

  const handleFilterChange = (type: number, item: string, event: ChangeEvent) => {
    const { target } = event;
    const currentTarget = target as HTMLInputElement;
    let selItems: string[] = [];
    if (type === 1) {
      selItems = SelectedAui;
    } else if (type === 2) {
      selItems = SelectedStages;
    } else if (type === 3) {
      selItems = SelectedPhases;
    } else if (type === 4) {
      selItems = SelectedCompounds;
    }
    const index = selItems.indexOf(item);
    let changeItems = false;
    if (currentTarget.checked && index < 0) {
      selItems.push(item);
      currentTarget.checked = true;
      changeItems = true;
    } else if (!currentTarget.checked && index >= 0) {
      selItems.splice(index, 1);
      currentTarget.checked = false;
      changeItems = true;
    }

    if (changeItems) {
      if (type === 1) {
        setSelectedAui([...selItems]);
      } else if (type === 2) {
        setSelectedStages([...selItems]);
      } else if (type === 3) {
        setSelectedPhases([...selItems]);
      } else if (type === 4) {
        setSelectedCompounds([...selItems]);
      }
    }
  };

  const showCTsAsPerSelectedItems = () => {
    let queryStr = '';
    if (SelectedAui.length > 0) {
      queryStr = `aui=${SelectedAui.join(',')}`;
    }

    if (SelectedStages.length > 0) {
      if (queryStr.length > 0) {
        queryStr = `${queryStr}&`;
      }
      queryStr = `${queryStr}stage=${SelectedStages.join(',')}`;
    }

    if (SelectedPhases.length > 0) {
      if (queryStr.length > 0) {
        queryStr = `${queryStr}&`;
      }
      queryStr = `${queryStr}phase=${SelectedPhases.join(',')}`;
    }

    if (SelectedCompounds.length > 0) {
      if (queryStr.length > 0) {
        queryStr = `${queryStr}&`;
      }
      queryStr = `${queryStr}tc=${SelectedCompounds.join(',')}`;
    }

    if (itemName.length > 0) {
      if (queryStr.length > 0) {
        queryStr = `${queryStr}&`;
      }
      queryStr = `${queryStr}name=${itemName}`;
    }

    // setQueryStr(queryStr);
    window.history.replaceState(null, document.title, `${getWindowPathName()}${queryStr.length > 0 ? `?${queryStr}` : ''}`);
  };

  const handleClear = () => {
    setSelectedStages([]);
    setSelectedPhases([]);
    setSelectedAui([]);
    setSelectedCompounds([]);
  };

  useEffect(() => {
    document.title = `${tumourType.label} | ${getSiteName()}`;
  }, []);

  useEffect(() => {
    showCTsAsPerSelectedItems();
  }, [SelectedAui, SelectedStages, SelectedPhases, SelectedCompounds]);

  useEffect(() => {
    changeAuiLabel();
  }, [SelectedAui]);

  let selFilterItems = SelectedStages.length + SelectedPhases.length + SelectedCompounds.length;
  if (SelectedAui.length >= 1 && SelectedAui[0] !== '-1') {
    selFilterItems += SelectedAui.length;
  }
  return (
    <MainContentWrapper wrapperClass="bg-mask-wrapper" wrapperStyle={mainStyle}>
      <div className="inner-container">
        <div className="container">
          <Info tumourType={tumourType} aui={auiLabel} />
          <div className="clear" />
        </div>
        <div style={{ background: '#0D1E35' }}>
          <div className="container" style={{ paddingTop: '25px' }}>
            <div className="container select-title-block">
              <div className="title-block">
                <div>
                  <h2><strong>Clinical trial information</strong></h2>
                  <p>Select trial for more information</p>
                </div>
                <div className="advance-filter-action">
                  <div className="filter-head">
                    <button style={{ display: 'none' }} type="button" className="filter-notification">
                      <span>{selFilterItems}</span>
                      <em className="az-icon icon-close" onClick={handleClear} />
                    </button>
                    {selFilterItems === 0 && (
                      <span className="label-filter">
                        Filters
                        <em className="az-icon icon-filter" />
                      </span>
                    )}
                    {selFilterItems > 0 && (
                      <button type="button" className="btn-filter" onClick={handleClear}>
                        Clear all
                        <em className="az-icon icon-close" />
                      </button>
                    )}
                  </div>
                  <div className="filter-data">
                    <FilterDropdown
                      title="Areas under investigation"
                      listStyle={{}}
                      data={tumorAreaUnderInvestigations.map((item) => (
                        { id: String(item.id), value: item.label }
                      ))}
                      checkedData={SelectedAui.map((item) => item)}
                      onItemChange={(id, e) => handleFilterChange(1, id, e)}
                      isDisabled={tumorAreaUnderInvestigations.length === 0}
                    />
                    <FilterDropdown
                      title="Stage"
                      listStyle={{ width: 'auto', minWidth: 80 }}
                      data={allStages.map((item) => ({ id: item, value: item }))}
                      checkedData={SelectedStages.map((item) => item)}
                      onItemChange={(id, e) => handleFilterChange(2, id, e)}
                      isDisabled={allStages.length === 0}
                    />
                    <FilterDropdown
                      title="Phase"
                      listStyle={{ width: 'auto', minWidth: 80 }}
                      data={allPhases.map((item) => ({ id: item, value: item }))}
                      checkedData={SelectedPhases.map((item) => item)}
                      onItemChange={(id, e) => handleFilterChange(3, id, e)}
                      isDisabled={allPhases.length === 0}
                    />
                    {false && (
                      <FilterDropdown
                        title="Compound"
                        listStyle={{}}
                        data={tumorTargetCompound.map((item) => (
                          { id: String(item.id), value: item.label }
                        ))}
                        checkedData={SelectedCompounds.map((item) => item)}
                        onItemChange={(id, e) => handleFilterChange(4, id, e)}
                        isDisabled={tumorTargetCompound.length === 0}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <IndicationCTList mechanisms={allClinicalTrials} name={itemName} />
          </div>
        </div>
      </div>
    </MainContentWrapper>
  );
}

export default IndicationDetail;
