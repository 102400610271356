import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import ITargetCompound from '../../../../../interfaces/TargetCompound';
// import { compareStr } from '../../../../../helpers/String';
import IAreaUnderInvestigation from '../../../../../interfaces/AreaUnderInvestigation';

interface IProps {
  targetCompound: ITargetCompound, // Target/compound.
  auiList: IAreaUnderInvestigation[],
  backLink: string // Back link URL.
}

function Brief(props: IProps) {
  const { targetCompound, backLink, auiList } = props;
  /* const allAois = targetCompound.field_areas_under_investigation
    .filter((aoi, index) => targetCompound.field_areas_under_investigation
      .findIndex((d) => compareStr(d.label, aoi.label)) === index); */

  return (
    <div className="type-innerpage-title-row">
      <div className="back-action">
        <Link to={backLink} className="btn-back">
          <em className="az-icon icon-arrow-left" />
          BACK
        </Link>
      </div>
      <div className="col-title">
        <h1><strong>{ targetCompound.label }</strong></h1>
      </div>
      <div className="col-description">
        <p>
          <strong>Compound</strong>
          { targetCompound.field_compound_name }
        </p>
        <p><strong>Area under investigation</strong></p>
        <p>{ _.map(auiList, 'label').join(', ') }</p>
        <p>
          <strong>Scientific Pillar</strong>
          { targetCompound.field_associated_area && (
            <Link to={`/scientific-pillars?id=${targetCompound.field_associated_area.id}`} style={{ color: targetCompound.field_associated_area.field_color }}>
              { targetCompound.field_associated_area.label }
              {' '}
              <em className="az-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11.105" height="18.947" viewBox="0 0 11.105 18.947">
                  <path
                    id="Path_2267"
                    data-name="Path 2267"
                    d="M0,0,6.009,5.811,8.972,8.676,0,17.516"
                    transform="translate(0.702 0.719)"
                    fill="none"
                    stroke={targetCompound.field_associated_area.field_color}
                    strokeWidth="2"
                  />
                </svg>
              </em>
            </Link>
          ) }
        </p>
      </div>
    </div>
  );
}

export default Brief;
