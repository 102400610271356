import React from 'react';
import { Link } from 'react-router-dom';
import App from '../../../store/App';

function FooterNavigation() {
  const contactLink = App.GetContactLink();
  const cookiePolicyLink = App.GetCookiePolicyHtmlUrl();
  const menuLinks = App.GetFooterNavigation();

  /**
   * Handle Continue button click event.
   */
  /* const handleContinueClick = (e: any, refUrl: string) => {
    e.preventDefault();
    window.open(refUrl, '_system', 'location=yes');
  }; */
  const isInternal = (linkUrl: string) => {
    const trimUrl = linkUrl.trim();
    if (trimUrl.length > 0) {
      const firstChar = trimUrl.substring(0, 1);
      if (firstChar === '\\' || firstChar === '/') {
        return true;
      }
    }
    return false;
  };

  return (
    <ul className="menu">
      { menuLinks.map((link, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          {isInternal(link.url) && <Link to={link.url}>{link.label}</Link>}
          {!isInternal(link.url) && <a href={link.url} className="exclude-exit-ramp-with-popup">{ link.label }</a>}
        </li>
      ))}
      { cookiePolicyLink && (
        <li>
          <a href={cookiePolicyLink} className="CookieReportsLink exclude-exit-ramp-with-popup">Cookie Notice</a>
        </li>
      )}
      { contactLink && (
        <li>
          <a className="exclude-exit-ramp-with-popup" href={contactLink}>Contact Us</a>
        </li>
      )}
    </ul>
  );
}

export default FooterNavigation;
