import React, {
  ChangeEvent,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import iconSearch from '../../../assets/images/icons/icon-search-white.svg';
import iconClose from '../../../assets/images/icons/icon-close.svg';
import IApp from '../../../interfaces/App';
import AppContext from '../../../context/AppContext';
import ITargetCompound from '../../../interfaces/TargetCompound';
import IClinicalTrial from '../../../interfaces/ClinicalTrial';
import { getWindowPathName } from '../../../helpers/Common';
import { compareStr } from '../../../helpers/String';

interface ITargetCompoundSearchItem {
  targetCompound: ITargetCompound,
  space: number,
  url: string,
}

interface IClinicalTrialSearchItem {
  clinicalTrial: IClinicalTrial,
  space: number,
  url: string,
}

const useKeyPress = function (targetKey: any) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key } : {key: any}) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key } : {key: any}) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
};

const ItemSearch = () => {
  const data: IApp = useContext(AppContext);
  const history = useHistory();
  const textInput = useRef<HTMLInputElement>(null);
  const searchTriggerLength: number = 1;
  const maxSearchItem: number = 3;

  const [enableSearch, setEnableSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showSuggestionList, setShowSuggestionList] = useState(false);
  const [targetSearchItem, setTargetSearchItem] = useState<ITargetCompoundSearchItem[]>([]);
  const [clinicalSearchItem, setClinicalSearchItem] = useState<IClinicalTrialSearchItem[]>([]);
  const [cursor, setCursor] = useState(0);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const escapePress = useKeyPress('Escape');

  const resetSearch = () => {
    setEnableSearch(false);
    setSearchText('');
    setShowSuggestionList(false);
    setTargetSearchItem([]);
    setClinicalSearchItem([]);
    setCursor(0);
  };

  function inputTextDownHandler(event: KeyboardEvent) {
    if (showSuggestionList && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
      event.preventDefault();
    }
  }

  useEffect(() => {
    if (textInput.current) {
      textInput.current.addEventListener('keydown', inputTextDownHandler);
    }

    return () => {
      if (textInput.current) {
        textInput.current.removeEventListener('keydown', inputTextDownHandler);
      }
    };
  });

  useEffect(() => {
    if ((targetSearchItem.length + clinicalSearchItem.length) && downPress) {
      const length = (targetSearchItem.length + clinicalSearchItem.length);
      setCursor((prevState) => (
        prevState < length - 1 ? prevState + 1 : prevState));
    }
  }, [downPress]);

  useEffect(() => {
    if ((targetSearchItem.length + clinicalSearchItem.length) && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);

  useEffect(() => {
    if ((targetSearchItem.length + clinicalSearchItem.length) && enterPress) {
      let url = '';
      if (cursor > -1 && cursor < clinicalSearchItem.length) {
        url = clinicalSearchItem[cursor].url;
      } else if (cursor > -1 && cursor < clinicalSearchItem.length + targetSearchItem.length) {
        url = targetSearchItem[cursor - clinicalSearchItem.length].url;
      }

      if (url.length > 0) {
        resetSearch();
        history.push(url, { prevPath: getWindowPathName() });
      }
    }
  }, [cursor, enterPress]);

  useEffect(() => {
    if (escapePress) {
      resetSearch();
    }
  }, [escapePress]);

  function getWordSearchIndex(inputString: string, keywordStr: string): number {
    const labelName = inputString.toLowerCase();
    const word = keywordStr.trim().toLowerCase();
    const index = labelName.indexOf(word);
    if (index === 0) {
      return index;
    }

    let searchIndex = 1;
    while (true) {
      const match = labelName.indexOf(word, searchIndex);
      if (match < 0) {
        return -1;
      }
      if (' -/('.includes(labelName.charAt(match - 1))) {
        return match;
      }
      searchIndex = match + 1;
    }
  }

  function GetClinicalSearchItem(appData: IApp, searchString: string): IClinicalTrialSearchItem[] {
    const searchTxt = searchString.trim().toLowerCase();
    const resultData: IClinicalTrialSearchItem[] = [];
    if (appData && appData.field_tumour_type && searchTxt.length >= searchTriggerLength) {
      appData.field_tumour_type.forEach((ftt) => {
        // ftt.field_targets_compounds.forEach((ftc) => {
        ftt.field_areas_under_investigation.forEach((faui) => {
          faui.field_clinical_trials.forEach((ct) => {
            const ctIndex = getWordSearchIndex(ct.label, searchTxt);
            if (ctIndex > -1) {
              const filterItem = resultData.filter((item) => item.clinicalTrial.id === ct.id);
              if (filterItem.length === 0) {
                let spaceCount = 9999;
                if (ctIndex === 0) {
                  spaceCount = 0;
                } else {
                  spaceCount = ct.label.substr(0, ctIndex + 1).split(/[ -/(]+/).length;
                }
                let tcId = faui.target_compound_id;
                if (!tcId || Number(tcId) < 0) {
                  const newTC = ftt.field_targets_compounds.find((ftc) => (
                    compareStr(ftc.label, faui.field_compound_name)
                    || compareStr(ftc.field_compound_name, faui.field_compound_name)
                  ));
                  if (newTC) {
                    tcId = String(newTC.id);
                  }
                }
                if (tcId) {
                  const ctUrl = `/tumour-type/${ftt.id}/target-compound/${tcId}?tab=clinical-trials&trial=${ct.id}`;
                  resultData.push({ clinicalTrial: ct, space: spaceCount, url: ctUrl });
                }
              }
            }
          });
        });
        // });
      });
    }

    if (resultData.length > 0) {
      resultData.sort((a, b) => {
        if (a.space === b.space) {
          return `${a.clinicalTrial.label} ${a.clinicalTrial.label}`.localeCompare(`${b.clinicalTrial.label} ${b.clinicalTrial.label}`);
        }
        return (a.space > b.space ? 1 : -1);
      });
    }
    if (resultData.length > maxSearchItem) {
      resultData.length = maxSearchItem;
    }
    return resultData;
  }

  // Get footer navigation inks.
  function GetTCSearchItem(appData: IApp, searchString: string): ITargetCompoundSearchItem[] {
    const searchTxt = searchString.trim().toLowerCase();
    const resultData: ITargetCompoundSearchItem[] = [];
    if (appData && appData.field_tumour_type && searchTxt.length >= searchTriggerLength) {
      appData.field_tumour_type.forEach((t) => {
        t.field_targets_compounds.forEach((tc) => {
          const labelIndex = getWordSearchIndex(tc.label, searchTxt);
          const compoundIndex = getWordSearchIndex(tc.field_compound_name, searchTxt);
          if (labelIndex > -1 || compoundIndex > -1) {
            const filterItem = resultData.filter((item) => item.targetCompound.id === tc.id);
            if (filterItem.length === 0) {
              let spaceCount = 9999;
              if (labelIndex === 0 || compoundIndex === 0) {
                spaceCount = 0;
              } else {
                if (labelIndex > 0) {
                  spaceCount = tc.label.substr(0, labelIndex + 1).split(/[ -/(]+/).length;
                }
                if (compoundIndex > 0) {
                  const compSpace = tc.field_compound_name.substr(0, labelIndex + 1).split(/[ -/(]+/).length;
                  spaceCount = compSpace < spaceCount ? compSpace : spaceCount;
                }
              }
              const tcUrl = `/tumour-type/${t.id}/target-compound/${tc.id}`;
              resultData.push({ targetCompound: tc, space: spaceCount, url: tcUrl });
            }
          }
        });
      });
    }
    if (resultData.length > 0) {
      resultData.sort((a, b) => {
        if (a.space === b.space) {
          return `${a.targetCompound.label} ${a.targetCompound.field_compound_name}`.localeCompare(`${b.targetCompound.label} ${b.targetCompound.field_compound_name}`);
        }
        return (a.space > b.space ? 1 : -1);
      });
    }
    if (resultData.length > maxSearchItem) {
      resultData.length = maxSearchItem;
    }
    return resultData;
  }

  function handleSearchClick() {
    setEnableSearch(!enableSearch);
    if (!enableSearch) {
      setTimeout(() => textInput?.current?.focus(), 300);
    } else {
      resetSearch();
    }
  }

  const handleSearchInputChange = (event: ChangeEvent) => {
    const { target } = event;
    const inputTarget = target as HTMLInputElement;
    const searchLength = inputTarget.value.trim().length;
    setSearchText(inputTarget.value);
    if (searchLength >= searchTriggerLength && !showSuggestionList) {
      setShowSuggestionList(true);
    } else if (searchLength < searchTriggerLength && showSuggestionList) {
      setShowSuggestionList(false);
    }

    if (cursor !== 0) {
      setCursor(0);
    }

    const tcItems: ITargetCompoundSearchItem[] = GetTCSearchItem(data, inputTarget.value);
    setTargetSearchItem(tcItems);

    const ctItems: IClinicalTrialSearchItem[] = GetClinicalSearchItem(data, inputTarget.value);
    setClinicalSearchItem(ctItems);
  };

  const makeBold = (item: string, keyword: string, url: string) => {
    const searchWord = keyword.trim();
    const index = getWordSearchIndex(item, searchWord);
    let newStr = (<>{item}</>);
    if (index > -1) {
      newStr = (
        <>
          {item.substring(0, index)}
          <strong>{item.substr(index, searchWord.length)}</strong>
          {item.substring(index + searchWord.length)}
        </>
      );
    }
    return (<Link to={url} onClick={resetSearch}>{newStr}</Link>);
  };

  return (
    <>
      <button type="button" className={`nav-search action-search ${enableSearch ? 'hide' : ''}`} onClick={handleSearchClick}><img src={iconSearch} alt="Search" /></button>
      <div className={`search-container ${enableSearch ? 'active active-close' : ''}`}>
        {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
        <label htmlFor="site_search" className="is-hide">Search</label>
        <input ref={textInput} className="search search-input" id="site_search" type="text" name="site_search" placeholder="Search" autoComplete="off" value={searchText} onChange={handleSearchInputChange} />
        <button className="search-btn search-close-btn" type="button" onClick={handleSearchClick}>
          <img src={iconClose} alt="Close" />
        </button>
        <ul className={`suggestion-list ${showSuggestionList ? 'active' : ''}`} id="suggestion-lists">
          <li className="suggestion-wise-list bg-light">
            <div className="list-title">Clinical trials</div>
            <ul>
              {clinicalSearchItem.length > 0 && clinicalSearchItem.map((item, index) => (
                <li
                  key={`ct_${item.clinicalTrial.id}`}
                  className={index === cursor ? 'active' : ''}
                  onMouseEnter={() => setCursor(index)}
                  onMouseLeave={() => setCursor(-1)}
                >
                  {makeBold(`${item.clinicalTrial.label}`, searchText, item.url) }
                </li>
              ))}
              {clinicalSearchItem.length === 0 && <li><span>No results found</span></li>}
            </ul>
          </li>
          <li className="suggestion-wise-list">
            <div className="list-title">Target / Compounds</div>
            <ul>
              {targetSearchItem.length > 0 && targetSearchItem.map((item, index) => (
                <li
                  key={`tc_${item.targetCompound.id}`}
                  className={clinicalSearchItem.length + index === cursor ? 'active' : ''}
                  onMouseEnter={() => setCursor(index + clinicalSearchItem.length)}
                  onMouseLeave={() => setCursor(-1)}
                >
                  {makeBold(`${item.targetCompound.label} / ${item.targetCompound.field_compound_name}`, searchText, item.url) }
                </li>
              ))}
              {targetSearchItem.length === 0 && <li><span>No results found</span></li>}
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ItemSearch;
