import { useContext } from 'react';
import AppContext from '../context/AppContext';
import IApp from '../interfaces/App';
import ITargetCompound from '../interfaces/TargetCompound';
import ITumourType from '../interfaces/TumourType';
import INarrative from '../interfaces/Narrative';
import IAreaUnderInvestigation from '../interfaces/AreaUnderInvestigation';
import { compareStr } from '../helpers/String';

export default {
  // Get all tumour types.
  GetAll: (): ITumourType[] => {
    const data: IApp = useContext(AppContext);

    if (!data || !data.field_tumour_type) {
      return [];
    }

    return data.field_tumour_type;
  },

  // Get tumour type by ID.
  GetById: (id: number): ITumourType | null => {
    const data: IApp = useContext(AppContext);

    if (!data || !data.field_tumour_type) {
      return null;
    }

    const ttid = Number(id);

    return data.field_tumour_type
      .find((tumour) => Number(tumour.id) === ttid) || null;
  },

  // Get all target/compounds from tumour type ID.
  GetTargetCompounds: (tumourId: number): ITargetCompound[] => {
    const data: IApp = useContext(AppContext);
    const ordering = Number(data.field_respect_tc_ordering);

    if (!data || !data.field_tumour_type) {
      return [];
    }

    const ttid = Number(tumourId);

    const tumourType = data.field_tumour_type.find((tumour) => Number(tumour.id) === ttid);

    const compounds = tumourType ? [...tumourType.field_targets_compounds] : [];

    if (ordering) {
      compounds
        .sort((a, b) => {
          if (compareStr(a.label, b.label)) {
            return a.field_compound_name.localeCompare(b.field_compound_name);
          }
          return a.label.localeCompare(b.label);
        });
    }

    return compounds;
  },

  GetTargetCompoundsByAUI: (data: IApp, id: number): ITargetCompound[] => {
    const ordering = Number(data.field_respect_tc_ordering);

    if (!data || !data.field_tumour_type) {
      return [];
    }

    const ttid = Number(id);

    const tumourType = data.field_tumour_type.find((tumour) => Number(tumour.id) === ttid);

    const tumourTC = tumourType ? [...tumourType.field_targets_compounds] : [];
    const compounds: ITargetCompound[] = tumourType ? [...tumourType.field_targets_compounds] : [];
    tumourType?.field_areas_under_investigation.forEach((aui) => {
      let targetComp = tumourTC
        .find((tc) => Number(tc.id) === Number(aui.target_compound_id));

      if (!targetComp && aui.target_compound_id) {
        data?.field_tumour_type?.forEach((tt) => {
          if (!targetComp) {
            targetComp = tt.field_targets_compounds
              .find((tc) => Number(tc.id) === Number(aui.target_compound_id));
          }
        });
      }

      if (targetComp && tumourTC.length > 0) {
        const fIndex = compounds.findIndex(
          (cl) => Number(cl.id) === Number(targetComp?.id),
        );
        if (fIndex === -1) {
          compounds.push(targetComp);
        }
      }
    });

    if (ordering) {
      compounds
        .sort((a, b) => {
          if (compareStr(a.field_compound_name, b.field_compound_name)) {
            return a.label.localeCompare(b.label);
          }
          return a.field_compound_name.localeCompare(b.field_compound_name);
        });
    }

    return compounds;
  },

  // Get all area under investigations from tumour type ID.
  GetAreaUnderInvestigations: (
    id: number,
    duplicate: boolean = false,
  ): IAreaUnderInvestigation[] => {
    const data: IApp = useContext(AppContext);
    const ordering = Number(data.field_respect_tc_ordering);

    if (!data || !data.field_tumour_type) {
      return [];
    }

    const ttid = Number(id);
    const areaUnderInvestigations: IAreaUnderInvestigation[] = [];

    let tumourTypes = data.field_tumour_type;
    if (ttid > -1) {
      const tumourType = data.field_tumour_type.find((tumour) => Number(tumour.id) === ttid);
      tumourTypes = tumourType ? [tumourType] : [];
    }

    tumourTypes.forEach((tt) => {
      tt.field_areas_under_investigation.forEach((aui) => {
        const newAui = aui;
        // newAui.field_compound_id = comp.id;
        // newAui.field_compound_label = tt.label;
        let targetComp = tt.field_targets_compounds
          .find((tc) => Number(tc.id) === Number(aui.target_compound_id));
        if (!targetComp && aui.target_compound_id) {
          targetComp = tt.field_targets_compounds
            .find((tc) => compareStr(tc.label, aui.field_compound_name));
        }
        if (!targetComp && aui.target_compound_id) {
          data?.field_tumour_type?.forEach((tts) => {
            if (!targetComp) {
              targetComp = tts.field_targets_compounds
                .find((tc) => Number(tc.id) === Number(aui.target_compound_id));
            }
          });
        }

        if (targetComp) {
          newAui.field_target_label = targetComp.label;
          newAui.field_compound_label = targetComp.field_compound_name;
          newAui.field_compound_id = targetComp.id;
        } else {
          console.log('aui tc not found', aui);
        }
        if (duplicate) {
          areaUnderInvestigations.push(newAui);
        } else {
          const filter = areaUnderInvestigations
            .filter((item) => compareStr(item.label, aui.label));
          if (filter.length === 0) {
            areaUnderInvestigations.push(newAui);
          }
        }
      });
    });
    if (ordering) {
      areaUnderInvestigations
        .sort((a, b) => {
          if (compareStr(a.label, b.label)) {
            return a.field_compound_label.localeCompare(b.field_compound_label);
          }
          return a.label.localeCompare(b.label);
        });
    }

    return areaUnderInvestigations;
  },

  // Get narrative related to tumour type.
  GetNarrative: (id: number): INarrative | null => {
    const data: IApp = useContext(AppContext);
    if (!data || !data.narratives) {
      return null;
    }

    return data.narratives.find((item) => Number(item.tumour_type) === id) || null;
  },

  // Get footer navigation inks.
  GetAllClinicalTrialPhasesByTC: (tumorType: ITumourType | null): string[] => {
    if (!tumorType) {
      return [];
    }

    const phases: string[] = [];

    // @ts-ignore
    tumorType.field_targets_compounds.forEach((tc) => {
      tc.field_areas_under_investigation.forEach((aui) => {
        aui.field_clinical_trials.forEach((ct) => {
          if (ct.field_phase && phases.findIndex((ph) => ph === ct.field_phase.label) < 0) {
            phases.push(ct.field_phase.label);
          }
        });
      });
    });
    phases.sort((a, b) => (a > b ? 1 : -1));
    return phases;
  },

  GetAllClinicalTrialPhasesByAUI: (tumorType: ITumourType | null): string[] => {
    if (!tumorType) {
      return [];
    }

    const phases: string[] = [];

    // @ts-ignore
    tumorType.field_areas_under_investigation.forEach((aui) => {
      aui.field_clinical_trials.forEach((ct) => {
        if (ct.field_phase && phases.findIndex((ph) => ph === ct.field_phase.label) < 0) {
          phases.push(ct.field_phase.label);
        }
      });
    });
    phases.sort((a, b) => (a > b ? 1 : -1));
    return phases;
  },

  // Get footer navigation inks.
  GetAllClinicalTrialStagesByTC: (tumorType: ITumourType | null): string[] => {
    if (!tumorType) {
      return [];
    }

    const stages: string[] = [];

    // @ts-ignore
    tumorType.field_targets_compounds.forEach((tc) => {
      tc.field_areas_under_investigation.forEach((aui) => {
        aui.field_clinical_trials.forEach((ct) => {
          if (ct.field_stage && stages.findIndex((ph) => ph === ct.field_stage.title) < 0) {
            stages.push(ct.field_stage.title);
          }
        });
      });
    });
    stages.sort((a, b) => (a > b ? 1 : -1));
    return stages;
  },

  GetAllClinicalTrialStagesByAUI: (tumorType: ITumourType | null): string[] => {
    if (!tumorType) {
      return [];
    }

    const stages: string[] = [];

    // @ts-ignore
    tumorType.field_areas_under_investigation.forEach((aui) => {
      aui.field_clinical_trials.forEach((ct) => {
        if (ct.field_stage && stages.findIndex((ph) => ph === ct.field_stage.title) < 0) {
          stages.push(ct.field_stage.title);
        }
      });
    });
    stages.sort((a, b) => (a > b ? 1 : -1));
    return stages;
  },
};
