import React, { MouseEvent, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getWindowPathName } from '../../../../../helpers/Common';
import TumourType from '../../../../../store/TumourType';
import IApp from '../../../../../interfaces/App';
import AppContext from '../../../../../context/AppContext';
import IAreaUnderInvestigation from '../../../../../interfaces/AreaUnderInvestigation';

interface IProps {
  mechanisms: IAreaUnderInvestigation[], // Targets/compounds
}

function IndicationList(props: IProps) {
  const { id } = useParams<{ id: string }>();
  const data: IApp = useContext(AppContext);
  const tumourTypeId = Number(id);
  const tumourType = TumourType.GetById(tumourTypeId);
  const { mechanisms } = props;
  const history = useHistory();

  /**
   * Handle link click.
   *
   * @param tcid
   */
  function handleLink(tcid: number, event: MouseEvent) {
    event.preventDefault();
    const from = {
      pathname: `/tumour-type/${tumourTypeId}/indications`,
      search: `?aui=${tcid}`,
      state: {},
      prevPath: getWindowPathName(),
    };
    history.push(from);
  }

  if (mechanisms.length === 0) {
    return (
      <div className="type-action-card type-action-card-pr" style={{ minHeight: 500 }}>
        <span className="no-result-block">
          No results found
        </span>
      </div>
    );
  }

  const isCompoundHide = data?.label === 'Singapore - PROACTIVE' && tumourType?.label === 'Hematologic';
  return (
    <div className="type-action-card type-action-card-pr" style={{ minHeight: 500 }}>
      <ul className="type-sub-card type-sub-card-indication-lists">
        { mechanisms.map((item) => (
          <li key={item.id} style={item.label === 'AKT inhibition' && isCompoundHide ? { display: 'none' } : {}}>
            <noindex>
              <a
                href="#"
                onClick={(event) => handleLink(item.id, event)}
                className="card-block"
                rel="nofollow"
              >
                <div className="card-head">
                  <h3><strong>{ item.label }</strong></h3>
                </div>
              </a>
            </noindex>
          </li>
        )) }
      </ul>
    </div>
  );
}

export default IndicationList;
