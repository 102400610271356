import React from 'react';
import { Link } from 'react-router-dom';
import ITumourType from '../../../../../interfaces/TumourType';

interface IProps {
  tumourType: ITumourType, // Tumour type.
  aui: String
}

function Info(props: IProps) {
  const { tumourType, aui } = props;

  return (
    <div className="type-innerpage-title accessibility-title-tumour-type">
      <div className="back-action">
        <Link to={`/tumour-type/${tumourType.id}`} className="btn-back btn-box">
          <em className="az-icon icon-arrow-left" />
          BACK
        </Link>
      </div>
      <div className="bg-content-block a-mb-30">
        <h1>
          <strong>{`${tumourType.label}${aui.length === 0 ? ' Trials' : ''}`}</strong>
          {aui.length > 0 && (
            <>
              {' > '}
              <strong>{ aui }</strong>
            </>
          )}
        </h1>
      </div>
    </div>
  );
}

export default Info;
