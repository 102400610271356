import React from 'react';
import { Link } from 'react-router-dom';
import ITumourType from '../../../../../interfaces/TumourType';
import TumourType from '../../../../../store/TumourType';

interface IProps {
  tumourType: ITumourType // Tumour type.
}

function Info(props: IProps) {
  const { tumourType } = props;
  const narrative = TumourType.GetNarrative(Number(tumourType.id));
  let narrativeTitle = 'Go to Narrative';
  if (narrative && tumourType && tumourType.label.toLowerCase() === 'lung') {
    narrativeTitle = 'Go to Lung Cancer Science';
  }

  return (
    <div className="type-innerpage-title accessibility-title-tumour-type">
      <div className="back-action">
        <Link to="/tumour-type" className="btn-back btn-box">
          <em className="az-icon icon-arrow-left" />
          BACK
        </Link>
      </div>
      <div className="bg-content-block a-mb-30">
        <h1>
          <strong>{ tumourType.label }</strong>
        </h1>
        {tumourType.field_description && (
          <p dangerouslySetInnerHTML={{ __html: tumourType.field_description }} />
        )}

        { narrative && (
          <div className="type-innerpage-title-action">
            <Link to={`/tumour-type/${tumourType.id}/narrative`} className="btn btn-light btn-icon btn-min-block btn-radius">
              {narrativeTitle}
              <em className="az-icon icon-arrow-right-white" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Info;
