import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getWindowLocationQuery, getWindowPathName } from '../../../../../helpers/Common';
import TumourType from '../../../../../store/TumourType';
import IClinicalTrial from '../../../../../interfaces/ClinicalTrial';
import ListItem from '../../../TargetCompound/Partials/ClinicalTrials/ListItem/ListItem';

interface IProps {
  mechanisms: IClinicalTrial[], // Targets/compounds
  name: string,
}

function IndicationCTList(props: IProps) {
  const { ttid } = useParams<{ ttid: string }>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loadFinish, setLoadFinish] = useState<boolean>(false);
  const [curPage, setCurPage] = useState<number>(0);
  const [clinicalItems, setClinicalItems] = useState<any[]>([]);
  const tumourTypeId = Number(ttid);
  const tumourType = TumourType.GetById(tumourTypeId);
  const { mechanisms, name } = props;
  const history = useHistory();

  /**
   * Callback trial selected event.
   *
   * @param clinicalTrial
   */
  function trialSelected(clinicalTrial: IClinicalTrial) {
    let tcId = -1;
    if (clinicalTrial.field_compound_id > -1) {
      tcId = clinicalTrial.field_compound_id;
    } else {
      tumourType?.field_targets_compounds.forEach((ftc) => {
        ftc.field_areas_under_investigation.forEach((faui) => {
          faui.field_clinical_trials.forEach((ct) => {
            if (ct.id === clinicalTrial.id) {
              tcId = ftc.id;
            }
          });
        });
      });
    }

    const currentUrl = `${getWindowPathName()}${getWindowLocationQuery()}`;
    const ctUrl = `/tumour-type/${tumourType?.id}/target-compound/${tcId}`;
    // window.history.replaceState(null, document.title, `${window.location.hostname}${ctUrl}`);
    const from = {
      pathname: ctUrl,
      state: { prevPath: currentUrl },
      search: `?tab=clinical-trials&trial=${clinicalTrial.id}`,
      prevPath: currentUrl,
    };
    history.push(from);
  }

  const loadAll = () => {
    setClinicalItems([...mechanisms]);
    setHasMore(false);
    setCurPage(mechanisms.length / 9);
  };

  const loadFunc = (pageToFetch: number) => {
    if (name.length === 0) {
      const itemsInPage = 9;
      const totalItems = mechanisms.length;
      const totalPages = totalItems / itemsInPage;

      setHasMore(totalPages > pageToFetch);
      setCurPage(pageToFetch);
      const newData = mechanisms.slice((pageToFetch - 1) * itemsInPage, itemsInPage);
      setClinicalItems([...clinicalItems, ...newData]);
    } else {
      loadAll();
    }
  };

  useEffect(() => {
    loadFunc(1);
    setTimeout(() => setLoadFinish(true));
  }, []);

  useEffect(() => {
    if (name.length === 0 && loadFinish) {
      loadAll();
    }
  }, [mechanisms]);

  if (clinicalItems.length === 0) {
    return (
      <div className="type-action-card type-action-card-pr" style={{ minHeight: 500 }}>
        <span className="no-result-block">
          No results found
        </span>
      </div>
    );
  }
  return (
    <div className="type-action-card type-action-card-pr" style={{ minHeight: 500 }}>
      <InfiniteScroll
        dataLength={clinicalItems.length}
        next={() => loadFunc(curPage + 1)}
        scrollThreshold="20px"
        hasMore={hasMore}
        style={{ margin: '-10px -10px 0' }}
        loader={() => {}}
        className="type-sub-card type-sub-card-dark"
      >
        { clinicalItems.map((trial) => (
          <li key={`${trial.id}_${trial.field_compound_id}`}>
            <ListItem
              clinicalTrial={trial}
              onTrialSelected={(ct) => trialSelected(ct)}
            />
          </li>
        ))}
      </InfiniteScroll>
    </div>
  );
}

export default IndicationCTList;
