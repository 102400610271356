import React, { MouseEvent } from 'react';
import IClinicalTrial from '../../../../../../interfaces/ClinicalTrial';
import LeavingPopup from '../LeavingPopup/LeavingPopup';
import { getWindowLocationQuery, getWindowPathName } from '../../../../../../helpers/Common';
import AppContext from '../../../../../../context/AppContext';
import StudyImageMap from './StudyImageMap';
import EligibilityCriteria from './EligibilityCriteria';

interface IProps {
  clinicalTrial: IClinicalTrial, // Clinical Trial.
  onClosed: () => void, // Closed event callback.
  /* eslint-disable */
  light?: boolean
}

interface IState {
  buttonUrl: string, // Clicked external button URL.
  takeMeLabel: string, // Clicked button is pubmed or not.
  title: string, // Document title.
}

class Overview extends React.Component<IProps, IState> {
  // eslint-disable-next-line react/static-property-placement
  static contextType = AppContext;

  activeEle:any = null;

  tabData:any[] = [];

  constructor(props: IProps) {
    super(props);
    this.state = {
      buttonUrl: '',
      takeMeLabel: '',
      title: document.title,
    };
    this.closePopup = this.closePopup.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getClinicalTrialUrl = this.getClinicalTrialUrl.bind(this);
  }

  showQRCodeModal() {
    const { label } = this.context;
    const appLabel = label || '';
    return appLabel.toLowerCase() === 'astrazeneca oncology pipeline tool' || appLabel.toLowerCase() === 'french proactive';
  }

  componentDidMount() {
    const { clinicalTrial } = this.props;
    const parts = document.title.split(' | ');
    parts.splice(-1, 0, clinicalTrial.label);
    document.title = parts.join(' | ');

    const tabs = document.querySelector<HTMLElement>('.custom-tabs');
    if (tabs) {
      window.scrollTo({
        top: tabs.offsetTop,
        behavior: 'smooth',
      });
    }
    setTimeout(() => {
      const query = new URLSearchParams(getWindowLocationQuery());
      const modal = query.get('modal');
      if (modal) {
        if (modal === '1') {
          this.setState({
            buttonUrl: this.getClinicalTrialUrl(),
            takeMeLabel: 'ClinicalTrials.Gov',
          });
        } else if (modal === '2') {
          this.setState({
            buttonUrl: clinicalTrial.field_pubmed_url,
            takeMeLabel: 'PubMed.Gov',
          });
        } else if (modal === '3' && this.showQRCodeModal()) {
          this.setState({
            buttonUrl: clinicalTrial.field_study_locator_url,
            takeMeLabel: 'Study Locator',
          });
        }
      }
    });
    this.preventTabOutside();
  }

  componentWillUnmount() {
    const { title } = this.state;
    document.title = title;
  }

  /**
   * Handle external button click event.
   *
   * @param url
   * @param pubmed
   */
  handleExternalButtonClick(modal: string, url: string, takeMelbl: string, event: MouseEvent) {
    if (modal === '3' && !this.showQRCodeModal()) {
      return;
    }

    event.preventDefault();
    this.setState({
      buttonUrl: url,
      takeMeLabel: takeMelbl,
    });
    const { clinicalTrial } = this.props;
    window.history.replaceState(null, document.title, `${getWindowPathName()}?tab=clinical-trials&trial=${clinicalTrial.id}&modal=${modal}`);
  }

  /**
   * Handle close button click.
   */
  handleClose() {
    const { onClosed } = this.props;
    this.enableTabOutside();
    onClosed();
  }

  /**
   * Get Clinical Trial External URL.
   */
  getClinicalTrialUrl() {
    const { clinicalTrial } = this.props;

    return `https://clinicaltrials.gov/ct2/show/${clinicalTrial.field_identifier}`;
  }

  preventTabOutside = () => {
    const selector = 'a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';
    this.activeEle = document.activeElement;
    const modal = document.getElementById('moreinfoblock');
    if (modal) {
      const tabbableElements = document.querySelectorAll(selector);
      this.tabData = Array.from(tabbableElements)
      // filter out any elements within the modal
        .filter((elem) => !modal?.contains(elem))
        // store refs to the element and its original tabindex
        .map((elem) => {
          // capture original tab index, if it exists
          const tabIndex = elem.hasAttribute('tabindex')
            ? elem.getAttribute('tabindex')
            : null;
          // temporarily set the tabindex to -1
          elem.setAttribute('tabindex', '-1');
          return { elem, tabIndex };
        });
    }
  };

  enableTabOutside = () => {
    this.tabData.forEach(({ elem, tabIndex }) => {
      if (tabIndex === null) {
        elem.removeAttribute('tabindex');
      } else {
        elem.setAttribute('tabindex', tabIndex);
      }
    });
    this.tabData = [];
    if (this.activeEle) {
      this.activeEle.focus();
      this.activeEle = null;
    }
  };

  /**
   * Close popup event callback.
   *
   */
  closePopup() {
    this.setState({
      buttonUrl: '',
      takeMeLabel: '',
    });
    const { clinicalTrial } = this.props;
    window.history.replaceState(null, document.title, `${getWindowPathName()}?tab=clinical-trials&trial=${clinicalTrial.id}`);
  }

  render() {
    const { clinicalTrial, light } = this.props;
    const { buttonUrl, takeMeLabel } = this.state;
    const { field_show_study_locator_fields, field_enable_inclusion_exclusion, field_disable_ct_gov_button } = this.context;
    const showQRModal = this.showQRCodeModal();

    return (
      <div id="moreinfoblock" className={`more-info-block ${light ? 'more-info-block-model' : ''} is-show`}>
        <div className="more-info-content is-show">
          <h2>
            <strong>
              { clinicalTrial.label}
              {' '}
              trial overview
            </strong>
          </h2>
          <button type="button" aria-label="Close" className="close-info" onClick={this.handleClose}>
            <em className={`az-icon icon-close ${light ? 'icon-close-dark' : ''}`} />
          </button>
          <div className="more-info-grid">
            <div>
              <div className="step-overview-desc">
                <div className="step-col">
                  <strong>Tumor</strong>
                  <br />
                  { clinicalTrial.field_tumour}
                </div>
                <div className="step-col">
                  <strong>Trial Identifier</strong>
                  <br />
                  { clinicalTrial.field_identifier}
                </div>
                <div className={clinicalTrial.field_stage ? "step-overview-desc step-overview-two" : "step-col"}>
                  {clinicalTrial.field_stage && (
                    <>
                      <div className="step-col">
                        <strong>Phase</strong>
                        <br />
                        { clinicalTrial.field_phase.label}
                      </div>
                      <div className="step-col">
                        <strong>Stage</strong>
                        <br />
                        { clinicalTrial.field_stage.title}
                      </div>
                    </>
                  )}
                  {!clinicalTrial.field_stage && (
                    <>
                      <strong>Phase</strong>
                      <br />
                      { clinicalTrial.field_phase.label }
                    </>
                  )}
                </div>
              </div>
              { clinicalTrial.field_regimen && (
                <div className="step-overview-desc step-overview-one">
                  <div className="step-col">
                    <strong>Regimen</strong>
                    <br />
                    { clinicalTrial.field_regimen }
                  </div>
                </div>
              )}
            </div>
            <div className="action-block">
              {!Boolean(field_disable_ct_gov_button) && (
                <a href={`${getWindowPathName()}?tab=clinical-trials&trial=${clinicalTrial.id}&modal=1`} className={`btn ${light ? 'btn-theme' : 'btn-light'} btn-icon`} onClick={this.handleExternalButtonClick.bind(this, '1', this.getClinicalTrialUrl(), 'ClinicalTrials.Gov')}>
                  {' '}
                  <em className="az-icon icon-link" />
                  {' '}
                  Visit ct.gov
                  {' '}
                  <em className="az-icon icon-arrow-right-white" />
                </a>
              )}

              { clinicalTrial.field_pubmed_url && (
                <a href={`${getWindowPathName()}?tab=clinical-trials&trial=${clinicalTrial.id}&modal=2`} className={`btn ${light ? 'btn-theme' : 'btn-light'} btn-icon`} onClick={this.handleExternalButtonClick.bind(this, '2', clinicalTrial.field_pubmed_url, 'PubMed.Gov')}>
                  <em className="az-icon icon-link" />
                  {' '}
                  Visit PubMed
                  {' '}
                  <em className="az-icon icon-arrow-right-white" />
                </a>
              )}
            </div>
          </div>
          { clinicalTrial.field_study_design && (
            <>
              <h3><strong>Study Trial Design</strong></h3>
              <div className="image-with-action">
                <div className="image-block">
                  <StudyImageMap srcImg={clinicalTrial.field_study_design} />
                </div>
              </div>
            </>
          )}
          {(Boolean(field_enable_inclusion_exclusion) && (clinicalTrial.field_inclusion_criteria || clinicalTrial.field_exclusion_criteria)) && (
            <EligibilityCriteria
              inclusion={clinicalTrial.field_inclusion_criteria}
              exclusion={clinicalTrial.field_exclusion_criteria}
            />
          )}
          {(Boolean(field_show_study_locator_fields) && (clinicalTrial.field_study_locator_description || clinicalTrial.field_study_locator_url)) && (
            <div className="bottom-info-row">
              <div className="brief-summary-block">
                <h3><strong>Study Locator</strong></h3>
                {clinicalTrial.field_study_locator_description && (
                  <p>{clinicalTrial.field_study_locator_description}</p>
                )}
                {clinicalTrial.field_study_locator_url && (
                  <div className="pre-screen-action action-block mb-0">
                    <a
                      href={showQRModal ? `${getWindowPathName()}?tab=clinical-trials&trial=${clinicalTrial.id}&modal=3` : clinicalTrial.field_study_locator_url}
                      className="btn btn-purple2 btn-icon exclude-iframe"
                      onClick={this.handleExternalButtonClick.bind(this, '3', clinicalTrial.field_study_locator_url, 'Study Locator')}
                    >
                      {' '}
                      <em className="az-icon icon-link" />
                      {' '}
                      Visit Study Locator
                      {' '}
                      <em className="az-icon icon-arrow-right-white" />
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        { buttonUrl
        && (
          <LeavingPopup
            url={buttonUrl}
            takeMeLabel={takeMeLabel}
            excludeIFrame={takeMeLabel.toLowerCase().includes('locator')}
            isLocator={this.showQRCodeModal()}
            clinicalTrial={clinicalTrial}
            onClose={this.closePopup}
          />
        )}
      </div>
    );
  }
}

export default Overview;
