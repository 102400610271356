import React, { useState, MouseEvent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Info from './Partials/Info/Info';
import ImageMap from './Partials/ImageMap/ImageMap';
// import bg from '../../../assets/images/type-bg-8.webp';
import TargetCompoundSidebar from '../../Shared/TargetCompoundSidebar/TargetCompoundSidebar';
import DDR from '../../../store/DDR';
import IDDRHotspot from '../../../interfaces/DDRHotspot';
import IDDR from '../../../interfaces/DDR';
import Tabs from './Partials/Tabs/Tabs';
import { splitString } from '../../../helpers/String';
import getSiteName, { getWindowLocationQuery, getWindowPathName } from '../../../helpers/Common';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';

function DDROverview() {
  const ddrs = DDR.GetAll();

  if (!ddrs.length) {
    return (<></>);
  }

  // Selected hotspot object.
  const [activeHotspot, setHotspot] = useState<IDDRHotspot | null>(null);

  const locationState: any = useLocation().state;
  let defaultDDR = ddrs[0];
  const query = new URLSearchParams(getWindowLocationQuery());
  const urlTab = query.get('tab');
  if (urlTab) {
    const tabId = Number(urlTab);
    const activeDDR = ddrs.find(((ddr) => Number(ddr.id) === tabId));
    if (activeDDR) {
      defaultDDR = activeDDR;
    }
  } else if (locationState && locationState.activeDDRId) {
    const activeDDRId = Number(locationState.activeDDRId);
    const activeDDR = ddrs.find(((ddr) => Number(ddr.id) === activeDDRId));
    if (activeDDR) {
      defaultDDR = activeDDR;
    }
  }

  // Selected DDR.
  const [activeDDR, setDDR] = useState<IDDR>(defaultDDR);

  // Reset scale.
  const [reset, setReset] = useState(false);

  /* const mainStyle = {
    backgroundImage: `url(${bg})`,
  }; */

  useEffect(() => {
    document.title = `Cell Pathway | ${activeDDR.title} | ${getSiteName()}`;
  }, []);

  /**
   * Handle hotspot selected event.
   *
   * @param hotspot
   */
  function hotspotSelected(hotspot: IDDRHotspot) {
    setHotspot(hotspot);
    if (hotspot.tcId) {
      setReset(false);
    }

    if (hotspot.tabId) {
      const tab = ddrs.find((ddr) => Number(ddr.id) === Number(hotspot.tabId));
      if (tab) {
        setDDR(tab);
      }
    }
  }

  /**
   * Handle sidebar closed event.
   *
   */
  function sidebarClosed() {
    setHotspot(null);
    setReset(true);
    window.history.replaceState(null, document.title, `${getWindowPathName()}?tab=${activeDDR.id}`);
  }

  /**
   * Handle tab select.
   *
   * @param ddr
   */
  function handleTabSelect(event: MouseEvent, ddr: IDDR) {
    setDDR(ddr);
  }

  return (
    <MainContentWrapper wrapperClass="cell-pathway-details">
      <div className="inner-container">
        <div className="container">
          <Info title={activeDDR.title} description={activeDDR.field_description} />
          <div className="clear" />
          <div className="custom-tab-block">
            <div className="container">
              <Tabs
                items={ddrs}
                activeId={Number(activeDDR.id)}
                onTabSelected={(selectedDDR) => setDDR(selectedDDR)}
              />
            </div>
            <div className="ddr-tab-content-block">
              { ddrs.map((ddr) => (
                <div className={`tab-content ${ddr.id === activeDDR.id ? 'active' : ''}`}>
                  <div className={`tab-header collapse-action ${ddr.id === activeDDR.id ? 'active' : ''}`} onClick={(event) => handleTabSelect(event, ddr)}>
                    <h2>
                      <strong>
                        { splitString(ddr.title)}
                        {' '}
                        <small>{ splitString(ddr.title, false) }</small>
                      </strong>
                    </h2>
                    <em className="az-icon icon-arrow-right-white" />
                  </div>
                  <div className={`tab-content-body ${ddr.id === activeDDR.id ? 'active' : ''}`}>
                    {ddr.id === activeDDR.id && (
                      <ImageMap
                        ddr={activeDDR}
                        reset={reset}
                        onHotspotSelected={(hotspot) => hotspotSelected(hotspot)}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      { activeHotspot && activeHotspot.target_compound && (
        <TargetCompoundSidebar
          activeDDRId={activeDDR.id}
          targetCompound={activeHotspot.target_compound}
          onClosed={() => sidebarClosed()}
        />
      ) }
    </MainContentWrapper>
  );
}

export default DDROverview;
