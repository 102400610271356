import React, { useEffect } from 'react';

interface IProps {
  wrapperClass?: string,
  wrapperStyle?: {},
  animateBodyImg?: boolean,
  addBodyHomeClass?: boolean,
}

function MainContentWrapper(props: React.PropsWithChildren<IProps>) {
  const {
    wrapperClass,
    wrapperStyle,
    animateBodyImg,
    addBodyHomeClass,
    children,
  } = props;

  // const [windoW, SetWindow] = useState(0);
  // const [footerOff, SetOffset] = useState(0);
  // const [footerH, SetFooterH] = useState(0);

  /* const setHeightOfWrapperClass = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1025) {
      let footerHeight = 300;
      const footerEle = document.querySelector('.main-footer');
      if (footerEle) {
        footerHeight = footerEle.clientHeight;
      }

      let footerOffset = 0;
      if (windowWidth >= 1600 && footerHeight > 200) {
        if (windowWidth <= 1720) {
          footerOffset = 50;
        } else if (windowWidth <= 1940) {
          footerOffset = 20;
        } else {
          footerOffset = -20;
        }
      }

      // SetWindow(windowWidth);
      // SetOffset(footerOffset);
      // SetFooterH(footerHeight);

      const minHeightString = `calc(100vh - ${footerHeight - footerOffset}px)`;
      const tansitionWrapperElements = document.getElementsByClassName('transition-wrapper');
      if (tansitionWrapperElements && tansitionWrapperElements.length > 0) {
        (tansitionWrapperElements[0] as HTMLElement).style.minHeight = minHeightString;
      }

      const mainContentElements = document.getElementsByClassName('main-content-wrapper');
      if (mainContentElements && mainContentElements.length > 0) {
        (mainContentElements[0] as HTMLElement).style.minHeight = minHeightString;
      }
    }
  }; */

  const handleResize = () => {
    // setHeightOfWrapperClass();
  };

  useEffect(() => {
    // setHeightOfWrapperClass();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    let bodyImg: any;
    if (animateBodyImg) {
      bodyImg = document.querySelector('.main-content-wrapper');
      setTimeout(() => {
        if (bodyImg) {
          bodyImg.classList.add('is-bg-active');
        }
      }, 100);
    }

    if (addBodyHomeClass) {
      document.body.classList.add('home');
    } else {
      document.body.classList.remove('home');
    }

    window.addEventListener('resize', handleResize);
    return () => {
      if (bodyImg && animateBodyImg) {
        bodyImg.classList.remove('is-bg-active');
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let finalStyle = {};
  if (wrapperStyle) {
    finalStyle = { ...finalStyle, ...wrapperStyle };
  }

  return (
    <main className={`main-content-wrapper ${wrapperClass}`} style={finalStyle}>
      {/* `${windoW} | ${footerOff} | ${footerH}` */}
      {children}
    </main>
  );
}

MainContentWrapper.defaultProps = {
  wrapperClass: '',
  wrapperStyle: {},
  animateBodyImg: false,
  addBodyHomeClass: false,
};

export default MainContentWrapper;
