import React, { ChangeEvent, MouseEvent } from 'react';
import ITargetCompound from '../../../../../interfaces/TargetCompound';
import IClinicalTrial from '../../../../../interfaces/ClinicalTrial';
import ListItem from './ListItem/ListItem';
import Overview from './Overview/Overview';
import AppContext from '../../../../../context/AppContext';
import { getWindowLocationQuery, getWindowPathName } from '../../../../../helpers/Common';
import FilterDropdown from '../../../../Shared/FilterDropdown/FilterDropdown';
// import { romanToArabic } from '../../../../../helpers/Number';
import ITumourType from '../../../../../interfaces/TumourType';
import auiStore from '../../../../../store/AreaUnderInvestigation';
import { compareStr } from '../../../../../helpers/String';
import IAreaUnderInvestigation from '../../../../../interfaces/AreaUnderInvestigation';

interface IProps {
  tumourType: ITumourType,
  targetCompound: ITargetCompound, // Target/compound.
  auiList: IAreaUnderInvestigation[],
  activeAOI: number[], // Areas Under Investigation.
  activePhases: string[], // selected phases
  activeStages: string[],
  active: boolean, // Is tab active
  fromScientificPillar: boolean,
  onSelected: () => void,
}

interface IState {
  allClinicalTrials: IClinicalTrial[], // All available clinical trials.
  selectedClinicalTrial: IClinicalTrial | null, // Selected Clinical Trial.
  allStages: string[],
  allPhases: string[],
  allAois: IAreaUnderInvestigation[],
  tumorSiteIds: string[], // Selected tumor sites.
  stageIds: string[], // selected stages
  phaseIds: string[], // selected phases
}

class ClinicalTrials extends React.Component<IProps, IState> {
  // eslint-disable-next-line react/static-property-placement
  static contextType = AppContext;

  constructor(props: IProps) {
    super(props);
    const usedActiveAOI = this.filterActiveAOI();
    const usedActivePhases = props.activePhases;
    const usedActiveStages = props.activeStages;
    this.state = {
      allClinicalTrials: [],
      selectedClinicalTrial: null,
      allStages: [],
      allPhases: [],
      allAois: [],
      tumorSiteIds: usedActiveAOI,
      phaseIds: usedActivePhases,
      stageIds: usedActiveStages,
    };
    this.trialUnselected = this.trialUnselected.bind(this);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleClearFilter = this.handleClearFilter.bind(this);
  }

  componentDidMount() {
    const {
      targetCompound,
      auiList,
      fromScientificPillar,
      tumourType,
    } = this.props;
    const mapPhases: string[] = [];
    const mapStages: string[] = [];
    let newAui = targetCompound.field_areas_under_investigation;
    if (!fromScientificPillar) {
      newAui = tumourType.field_areas_under_investigation;
    }
    newAui.forEach((t) => {
      if (t.field_clinical_trials
        && auiList.findIndex((auit) => compareStr(auit.label, t.label)) > -1
      ) {
        if (compareStr(t.field_compound_name, targetCompound.field_compound_name)
          || Number(t.field_compound_id) === Number(targetCompound.id)
          || Number(t.target_compound_id) === Number(targetCompound.id)) {
          t.field_clinical_trials.forEach((trial) => {
            if (trial.field_phase && !mapPhases.includes(trial.field_phase.label)) {
              mapPhases.push(trial.field_phase.label);
            }

            if (trial.field_stage && !mapStages.includes(trial.field_stage.title)) {
              mapStages.push(trial.field_stage.title);
            }
          });
        }
      }
    });
    mapPhases.sort((a, b) => (a > b ? 1 : -1));
    mapStages.sort((a, b) => (a > b ? 1 : -1));

    /* const mapAois = targetCompound.field_areas_under_investigation
      .filter((aoi, index) => targetCompound.field_areas_under_investigation
        .findIndex((d) => compareStr(d.label, aoi.label)) === index); */

    this.setState({
      allPhases: mapPhases,
      allStages: mapStages,
      allAois: auiList,
    });
    this.fetchCTs();
    this.setClinicalTrialBasedOnQuery();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    const { active, activeAOI, targetCompound } = this.props;
    const { tumorSiteIds, phaseIds, stageIds } = this.state;
    if (prevProps.active && !active) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedClinicalTrial: null,
      });
    }

    if (prevProps.active !== active || prevProps.targetCompound !== targetCompound) {
      const usedActiveAOI = activeAOI.filter((aoi) => this.getTumorSite(aoi));
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        tumorSiteIds: usedActiveAOI.map((aoi) => String(this.getTumorSite(aoi)?.id || '')),
      });
      setTimeout(() => {
        this.fetchCTs();
        this.setClinicalTrialBasedOnQuery();
      }, 500);
    }
    this.setClinicalTrialBasedOnQuery();

    if (prevState.tumorSiteIds !== tumorSiteIds
        || prevState.phaseIds !== phaseIds
        || prevState.stageIds !== stageIds) {
      this.fetchCTs();
    }
  }

  handleFilterChange = (type: number, item: string, event: ChangeEvent) => {
    const { target } = event;
    const { tumorSiteIds, stageIds, phaseIds } = this.state;
    const currentTarget = target as HTMLInputElement;
    let selItems: string[] = [];
    if (type === 1) {
      selItems = tumorSiteIds;
    } else if (type === 2) {
      selItems = stageIds;
    } else if (type === 3) {
      selItems = phaseIds;
    }
    const index = selItems.indexOf(item);
    let changeItems = false;
    if (currentTarget.checked && index < 0) {
      selItems.push(item);
      currentTarget.checked = true;
      changeItems = true;
    } else if (!currentTarget.checked && index >= 0) {
      selItems.splice(index, 1);
      currentTarget.checked = false;
      changeItems = true;
    }

    if (changeItems) {
      if (type === 1) {
        this.setState({ tumorSiteIds: [...selItems] });
      } else if (type === 2) {
        this.setState({ stageIds: [...selItems] });
      } else if (type === 3) {
        this.setState({ phaseIds: [...selItems] });
      }
    }
  };

  handleClearFilter = () => {
    this.setState({ tumorSiteIds: [] });
    this.setState({ phaseIds: [] });
    this.setState({ stageIds: [] });
  };

  /**
   * Callback on header click event.
   *
   */
  handleHeaderClick(event: MouseEvent) {
    const { onSelected } = this.props;
    const { target } = event;
    const elTarget = target as HTMLElement;
    onSelected();
    setTimeout(() => {
      window.scrollTo({
        top: elTarget.offsetTop - 10,
        behavior: 'smooth',
      });
    });
  }

  getClinicalTrials(
    areas: string[] = [],
    stage: string[] = [],
    phase: string[] = [],
  ): IClinicalTrial[] {
    const { targetCompound, tumourType, fromScientificPillar } = this.props;
    // eslint-disable-next-line camelcase
    const { field_respect_ct_ordering } = this.context;
    const cts: IClinicalTrial[] = auiStore.GetClinicalTrials(
      this.context,
      tumourType,
      areas,
      stage,
      phase,
      [targetCompound.id],
      Boolean(field_respect_ct_ordering),
      fromScientificPillar,
    );
    return cts;
  }

  setClinicalTrialBasedOnQuery() {
    const query = new URLSearchParams(getWindowLocationQuery());
    const trial = query.get('trial');
    const { selectedClinicalTrial } = this.state;
    if (trial && (!selectedClinicalTrial || selectedClinicalTrial.id.toString() !== trial)) {
      const cts = this.getClinicalTrials();
      const trialId = Number(trial);
      const clinicalTrial = cts.find((item) => Number(item.id) === trialId);
      if (clinicalTrial && selectedClinicalTrial !== clinicalTrial) {
        setTimeout(() => {
          this.setState({
            selectedClinicalTrial: clinicalTrial,
          });
        });
      }
    }
  }

  /**
   * Get tumor site by ID.
   *
   * @param id
   */
  getTumorSiteName(id: number | string) {
    const { auiList, tumourType } = this.props;
    let tumorSite = auiList.find((ts) => Number(ts.id) === Number(id));

    if (!tumorSite) {
      tumourType.field_targets_compounds.forEach((tc) => {
        tc.field_areas_under_investigation.forEach((ts) => {
          if (Number(ts.id) === Number(id)) {
            tumorSite = ts;
          }
        });
      });
    }

    return tumorSite ? tumorSite.label : '';
  }

  getTumorSite(id: number | string) {
    const { auiList } = this.props;
    const tumorSiteLabel = this.getTumorSiteName(id);
    const tumorSite = auiList.find((ts) => compareStr(ts.label, tumorSiteLabel));

    return tumorSite;
  }

  filterActiveAOI = () => {
    const { activeAOI } = this.props;
    const filterAOI = activeAOI.filter((aoi) => this.getTumorSite(aoi))
      .map((aoi1) => String(this.getTumorSite(aoi1)?.id || ''));
    const newAOIs: string[] = filterAOI.filter((aoi, index) => filterAOI.indexOf(aoi) === index);
    return newAOIs;
  };

  /**
   * Callback trial selected event.
   *
   * @param clinicalTrial
   */
  trialSelected(clinicalTrial: IClinicalTrial) {
    this.setState({
      selectedClinicalTrial: clinicalTrial,
    });
    window.history.replaceState(null, document.title, `${getWindowPathName()}?tab=clinical-trials&trial=${clinicalTrial.id}`);
  }

  /**
   * Trial unselected event callback.
   */
  trialUnselected() {
    this.setState({
      selectedClinicalTrial: null,
    });
    window.history.replaceState(null, document.title, `${getWindowPathName()}?tab=clinical-trials`);
  }

  /**
   * Fetch CTs and set into state.
   */
  fetchCTs() {
    const { tumorSiteIds, stageIds, phaseIds } = this.state;
    const cts = this.getClinicalTrials(tumorSiteIds, stageIds, phaseIds);

    this.setState({
      allClinicalTrials: cts,
    });
  }

  render() {
    const { active } = this.props;
    const {
      allClinicalTrials,
      selectedClinicalTrial,
      tumorSiteIds,
      phaseIds,
      stageIds,
      allPhases,
      allStages,
      allAois,
    } = this.state;

    const totalSelFilters = phaseIds.length + tumorSiteIds.length > 1 ? tumorSiteIds.length : 0;
    return (
      <div className={`tab-content ${active ? 'active' : ''}`}>
        <div className={`tab-header collapse-action ${active ? 'active' : ''}`} onClick={this.handleHeaderClick}>
          <h2><strong>Clinical Trials</strong></h2>
          <em className="az-icon icon-arrow-right-white" />
        </div>
        <div className={`tab-content-body ${active ? 'active' : ''}`}>
          <div className="container">
            { !selectedClinicalTrial && (
              <div className="clinical-trial-block">
                <div className="tab-content-title grid-col-2">
                  <div>
                    <h2><strong>Clinical trial information</strong></h2>
                    <p>Select trial for more information</p>
                  </div>
                  <div className="advance-filter-action">
                    <div className="filter-head">
                      <button style={{ display: 'none' }} type="button" className="filter-notification">
                        <span>{totalSelFilters}</span>
                        <em className="az-icon icon-close" onClick={this.handleClearFilter} />
                      </button>
                      {totalSelFilters === 0 && (
                        <span className="label-filter">
                          Filters
                          <em className="az-icon icon-filter" />
                        </span>
                      )}
                      {totalSelFilters > 0 && (
                        <button type="button" className="btn-filter" onClick={this.handleClearFilter}>
                          Clear all
                          <em className="az-icon icon-close" />
                        </button>
                      )}
                    </div>
                    <div className="filter-data">
                      {allAois.length > 1 && (
                        <FilterDropdown
                          title="Areas under investigation"
                          listStyle={{}}
                          data={allAois.map((item) => (
                            { id: String(item.id), value: item.label }
                          ))}
                          checkedData={tumorSiteIds.map((item) => String(item))}
                          onItemChange={(id, e) => this.handleFilterChange(1, id, e)}
                          isDisabled={allAois?.length === 0}
                        />
                      )}
                      <FilterDropdown
                        title="Stage"
                        listStyle={{ width: 'auto', minWidth: 80 }}
                        data={allStages.map((item) => ({ id: item, value: item }))}
                        checkedData={stageIds.map((item) => item)}
                        onItemChange={(id, e) => this.handleFilterChange(2, id, e)}
                        isDisabled={allStages.length === 0}
                      />
                      <FilterDropdown
                        title="Phase"
                        listStyle={{ width: 'auto', minWidth: 80 }}
                        data={allPhases.map((item) => ({ id: item, value: item }))}
                        checkedData={phaseIds.map((item) => item)}
                        onItemChange={(id, e) => this.handleFilterChange(3, id, e)}
                        isDisabled={allPhases.length === 0}
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-type-action-card clinical-data-block">
                  {allClinicalTrials.length === 0 && (
                    <div className="no-result-block">
                      <span>No results found</span>
                    </div>
                  )}
                  {allClinicalTrials.length > 0 && (
                    <ul className="type-sub-card type-sub-card-dark">
                      { allClinicalTrials.map((trial) => (
                        <li key={trial.id}>
                          <ListItem
                            clinicalTrial={trial}
                            onTrialSelected={(ct) => this.trialSelected(ct)}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
            { selectedClinicalTrial && (
              <Overview
                clinicalTrial={selectedClinicalTrial}
                onClosed={() => this.trialUnselected()}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ClinicalTrials;
