import _ from 'lodash';
import ITargetCompound from '../interfaces/TargetCompound';
import IClinicalTrial from '../interfaces/ClinicalTrial';
import TumourType from './TumourType';
import ITumourType from '../interfaces/TumourType';
import IAreaUnderInvestigation from '../interfaces/AreaUnderInvestigation';
import { compareStr } from '../helpers/String';
import IApp from '../interfaces/App';

export default {
  // Get Target/Compound by tumour type ID and target/compound ID.
  GetById: (
    tumourTypeId: number,
    areaUnderInvestigationId: number,
  ): IAreaUnderInvestigation | null => {
    const tumourType = TumourType.GetById(tumourTypeId);
    if (!tumourType || !tumourType.field_targets_compounds) {
      return null;
    }

    const auiId = Number(areaUnderInvestigationId);

    return TumourType.GetAreaUnderInvestigations(tumourTypeId, true)
      .find((aui) => Number(aui.id) === auiId) || null;
  },

  // Get all clinical trials of target/compound.
  GetClinicalTrials: (
    data: IApp,
    tumourType: ITumourType | null,
    auiIds: number[] | string[],
    stage: string[] = [],
    phase: string[] = [],
    targetCompounds: number[] | string[],
    ordering = false,
    checkCTInTC = false,
    fromIndication = false,
  ): IClinicalTrial[] => {
    // @ts-ignore
    if (!tumourType || data.field_tumour_type.length === 0) {
      return [];
    }
    // let trialIds: number[] = [];
    let clinicalTrials: IClinicalTrial[] = [];

    const filterAui: IAreaUnderInvestigation[] = [];
    const filterTc: ITargetCompound[] = [];
    const relatedTc: ITargetCompound[] = TumourType.GetTargetCompoundsByAUI(data, tumourType.id);
    if (!fromIndication || targetCompounds.length > 0) {
      relatedTc.forEach((tc) => {
        if (
          // targetCompounds.length === 0
          targetCompounds.findIndex((item: any) => Number(item) === Number(tc.id)) > -1
        ) {
          if (filterTc.findIndex((fItem) => Number(fItem.id) === Number(tc.id)) === -1) {
            filterTc.push(tc);
          }
        }
      });
    }

    if (checkCTInTC) {
      tumourType.field_targets_compounds.forEach((tarComp) => {
        tarComp.field_areas_under_investigation.forEach((aui) => {
          if (
            auiIds.length === 0
            || auiIds.findIndex((item: any) => Number(item) === Number(aui.id)) > -1
          ) {
            if (filterAui.findIndex((fItem) => Number(fItem.id) === Number(aui.id)) === -1) {
              filterAui.push(aui);
            }
          }
        });
      });
    } else {
      tumourType.field_areas_under_investigation.forEach((aui) => {
        if (
          auiIds.length === 0
            || auiIds.findIndex((item: any) => Number(item) === Number(aui.id)) > -1
        ) {
          if (filterAui.findIndex((fItem) => Number(fItem.id) === Number(aui.id)) === -1) {
            filterAui.push(aui);
          }
        }
      });

      _.forEach(tumourType.field_areas_under_investigation, (area) => {
        if (filterAui.findIndex((filaui) => compareStr(filaui.label, area.label)) > -1) {
          if (filterAui.findIndex((fItem) => Number(fItem.id) === Number(area.id)) === -1) {
            filterAui.push(area);
          }
        }
      });
    }

    // _.forEach(tumourType.field_targets_compounds, (tc) => {
    _.forEach(filterAui, (area) => {
      // if (filterAui.findIndex((filaui) => compareStr(filaui.label, area.label)) > -1) {
      _.forEach(area.field_clinical_trials, (trial) => {
        let duplicate = false;
        const fIndex = clinicalTrials.findIndex(
          (cl) => compareStr(cl.label, trial.label),
        );
        if (fIndex > -1) {
          if (Number(area.field_compound_id) > -1) {
            /* fIndex = clinicalTrials.findIndex(
              (cl) => Number(cl.field_compound_id) === Number(area.field_compound_id),
            ); */
            if (
              Number(clinicalTrials[fIndex].field_compound_id) === Number(trial.field_compound_id)
              || Number(clinicalTrials[fIndex].field_compound_id) === Number(area.field_compound_id)
            ) {
              duplicate = true;
            }
          } else {
            duplicate = true;
          }
        }

        if (fromIndication && !duplicate && fIndex > -1) {
          if (!clinicalTrials[fIndex].field_targets_compounds && !trial.field_targets_compounds) {
            clinicalTrials[fIndex].field_targets_compounds = trial.field_targets_compounds;
          }
          duplicate = true;
        }
        if (!duplicate) {
          if (stage.length === 0
              || (trial.field_stage && stage.indexOf(trial.field_stage.title) >= 0)) {
            if (phase.length === 0
                || (trial.field_phase && phase.indexOf(trial.field_phase.label) >= 0)) {
              if (
                filterTc.length === 0
                  || filterTc.findIndex(
                    (ftc) => (compareStr(ftc.label, area.field_compound_label)
                      || Number(ftc.id) === Number(area?.target_compound_id)),
                  ) >= 0
              ) {
                const newTrial = trial;
                if (area.target_compound_id) {
                  newTrial.field_compound_id = Number(area.target_compound_id);
                } else {
                  newTrial.field_compound_id = Number(area.field_compound_id);
                }
                newTrial.field_compound_label = area.field_compound_label;
                newTrial.field_target_label = area.field_target_label;

                if (
                  fromIndication
                  && trial.field_targets_compounds
                  && trial.field_targets_compounds.length > 0
                ) {
                  const inputTC = trial.field_targets_compounds[0];
                  let foundAui:any = null;
                  trial.field_targets_compounds.forEach((fTc) => {
                    if (!foundAui) {
                      foundAui = filterAui.find((faui) => (
                        Number(faui.field_compound_id) === Number(fTc.id)
                        || Number(faui.target_compound_id) === Number(fTc.id)
                        || (compareStr(faui.field_target_label, fTc.label)
                          && compareStr(faui.field_compound_label, fTc.field_compound_name))
                      ));
                    }
                  });
                  newTrial.field_compound_id = foundAui?.field_compound_id || inputTC.id;
                  newTrial.field_compound_label = foundAui?.field_compound_label
                    || inputTC.field_compound_name;
                  newTrial.field_target_label = foundAui?.field_target_label || inputTC.label;
                }
                // trialIds = [...trialIds, trial.id];
                clinicalTrials = [...clinicalTrials, newTrial];
              }
            }
          }
        }
      });
      // }
    });
    // });

    if (ordering) {
      clinicalTrials.sort((a, b) => a.label.localeCompare(b.label));
    }

    return clinicalTrials;
  },

  // Get tumour type by aui ID.
  GetTumourTypeByAUIId: (id: number): ITumourType | null => {
    const tumourTypes = TumourType.GetAll();
    const tumourType = tumourTypes.find((type) => type.field_targets_compounds
      .find((targetCompound) => targetCompound.field_areas_under_investigation
        .find((aui) => Number(aui.id) === id)));

    return tumourType || null;
  },

  // Get tumour type by aui ID.
  GetTargetCompoundByAUIId: (id: number): ITargetCompound | null => {
    const tumourTypes = TumourType.GetAll();
    _.forEach(tumourTypes, (area) => {
      _.forEach(area.field_targets_compounds, (tc) => {
        // eslint-disable-next-line consistent-return
        _.forEach(tc.field_areas_under_investigation, (aui) => {
          if (Number(aui.id) === id) {
            return tc;
          }
        });
      });
    });
    return null;
  },
};
