import React, { MouseEvent } from 'react';
import IDDR from '../../../../../interfaces/DDR';
import { splitString } from '../../../../../helpers/String';
import { getWindowPathName } from '../../../../../helpers/Common';

interface IProps {
  items: IDDR[], // DDRs.
  activeId: number, // Active DDR ID.
  // eslint-disable-next-line no-unused-vars
  onTabSelected: (ddr: IDDR) => void, // DDR selected callback.
}

interface IState {}

class Tabs extends React.Component<IProps, IState> {
  /**
   * Handle tab click.
   *
   * @param ddr
   * @param event
   */
  handleTabClick(ddr: IDDR, event: MouseEvent) {
    event.preventDefault();
    const { onTabSelected, activeId } = this.props;
    onTabSelected(ddr);
    if (Number(ddr.id) !== activeId) {
      window.history.replaceState(null, document.title, `${getWindowPathName()}?tab=${ddr.id}`);
    }
  }

  render() {
    const { items, activeId } = this.props;
    return (
      <div className="ddr-tabs">
        { items.map((ddr) => (
          <a
            href={`${getWindowPathName()}?tab=${ddr.id}`}
            className={`${Number(ddr.id) === activeId ? 'active' : ''}`}
            onClick={this.handleTabClick.bind(this, ddr)}
            key={ddr.id}
          >
            <strong>
              { splitString(ddr.title) }
              <br />
              <small>{splitString(ddr.title, false)}</small>
            </strong>
          </a>
        ))}
      </div>
    );
  }
}

export default Tabs;
