import React, { useContext, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Brief from './Partials/Brief/Brief';
import Tabs from './Partials/Tabs/Tabs';
import TargetCompoundStore from '../../../store/TargetCompound';
import Overview from './Partials/Overview/Overview';
import NotFound from '../NotFound/NotFound';
import Abbreviations from './Partials/Abbreviations/Abbreviations';
import ClinicalTrials from './Partials/ClinicalTrials/ClinicalTrials';
import getSiteName from '../../../helpers/Common';
import TumourType from '../../../store/TumourType';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';
import IApp from '../../../interfaces/App';
import AppContext from '../../../context/AppContext';
import { compareStr } from '../../../helpers/String';
import IAreaUnderInvestigation from '../../../interfaces/AreaUnderInvestigation';

function TargetCompound() {
  const data: IApp = useContext(AppContext);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultTab = query.get('tab') || (window.innerWidth >= 767 ? 'overview' : '');
  const [activeTab, setActiveTab] = useState(defaultTab);
  const { ttid, tcid } = useParams<{ ttid: string, tcid: string }>();
  const tumourTypeId = Number(ttid);
  const targetCompoundId = Number(tcid.split('?')[0]);
  const tumourType = TumourType.GetById(tumourTypeId);
  // const targetCompound = TargetCompoundStore.GetById(tumourTypeId, targetCompoundId);
  const locationState: any = location.state;
  let showAOI = location && (location.pathname.indexOf('/scientific-pillars') !== 0 && location.pathname.indexOf('/compounds') !== 0);
  const fromScientific = location && location.pathname.indexOf('/scientific-pillars') === 0;

  const targetCompound = TargetCompoundStore
    .GetGlobalById(data?.field_tumour_type || null, targetCompoundId);
  let tcAuiList: IAreaUnderInvestigation[] = [];
  if (targetCompound) {
    if (fromScientific) {
      tcAuiList = [...targetCompound.field_areas_under_investigation
        .filter((aoi, index) => targetCompound.field_areas_under_investigation
          .findIndex((d) => compareStr(d.label, aoi.label)) === index)];
    } else {
      tumourType?.field_areas_under_investigation.forEach((aui) => {
        const foundIndex = targetCompound?.field_areas_under_investigation.findIndex((tcAui) => (
          (Number(tcAui.id) === Number(aui.id)) || compareStr(tcAui.label, aui.label)
        ));
        if (foundIndex > -1) {
          if (tcAuiList.findIndex((tcAui) => (
            (Number(tcAui.id) === Number(aui.id)) || compareStr(tcAui.label, aui.label)
          )) === -1) {
            tcAuiList = [...tcAuiList, aui];
          }
        }
      });
    }
  }
  let backLink = locationState && locationState.prevPath ? locationState.prevPath : `/tumour-type/${tumourTypeId}`;
  if (backLink.substr(0, 6) === '/draft') {
    backLink = backLink.substr(6);
  }
  if (locationState && locationState.prevPath) {
    if (backLink.substr(0, 1) === '#') {
      backLink = backLink.substr(1);
    }
  }
  const activePhases = locationState
    && locationState.selectedPhase ? locationState.selectedPhase.slice() : [];

  const activeStages = locationState
    && locationState.selectedStage ? locationState.selectedStage.slice() : [];

  if (locationState && locationState.prevPath && locationState.prevPath.indexOf('/cell-pathway') === 0) {
    showAOI = false;
  }

  if (locationState && (locationState.activeDDRId || locationState.selectedPhase)) {
    backLink = {
      pathname: backLink,
      state: {
        activeDDRId: locationState.activeDDRId,
        activePhase: locationState.selectedPhase && locationState.selectedPhase.slice(),
      },
    };
  }

  if (!tumourType || !targetCompound) {
    return (<NotFound />);
  }

  const handleResize = () => {
    if (window.innerWidth >= 767 && activeTab === '') {
      setActiveTab('overview');
    }
  };

  useEffect(() => {
    document.title = `${tumourType.label} | ${targetCompound.label} | ${getSiteName()}`;
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const mainStyle = targetCompound.field_target_image ? {
    backgroundImage: `url(${targetCompound.field_target_image})`,
  } : {};

  const tabClicked = (tab: string) => {
    const newTab = tab === activeTab ? '' : tab;
    setActiveTab(newTab);
  };

  let activeAOI: number[] = [];
  if (showAOI) {
    activeAOI = tumourType.field_areas_under_investigation
      ? tumourType.field_areas_under_investigation.map((aoi) => Number(aoi.id)) : [];
  }

  if (locationState && locationState.prevPath.indexOf('/indication') > 0) {
    activeAOI = [];
    const pathSplits = locationState.prevPath.split('?');
    if (pathSplits.length > 1) {
      const locQuery = new URLSearchParams(pathSplits[1]);
      const auiIds = locQuery.get('aui')?.split(',');
      activeAOI = auiIds?.map((item) => Number(item)) || [];
    }

    if (activeAOI.length === 0) {
      activeAOI = targetCompound.field_areas_under_investigation.map((item) => Number(item.id));
    }
  }

  useEffect(() => {
    if (defaultTab !== activeTab) {
      setActiveTab(defaultTab);
    }
  }, [location]);

  return (
    <MainContentWrapper wrapperClass="bg-tab-wrapper page-ani-fade" wrapperStyle={mainStyle}>
      <div className="inner-container">
        <div className="container">
          <Brief
            backLink={backLink}
            targetCompound={targetCompound}
            auiList={tcAuiList}
          />
          <div className="clear" />
        </div>
        <div className="custom-tab-block">
          <Tabs
            activeTab={activeTab}
            onTabClick={(tab) => setActiveTab(tab)}
          />
        </div>
        <div className="tab-content-block">
          <Overview
            targetCompound={targetCompound}
            defaultTab={false}
            active={activeTab === 'overview'}
            onSelected={() => tabClicked('overview')}
          />
          <ClinicalTrials
            tumourType={tumourType}
            targetCompound={targetCompound}
            auiList={tcAuiList}
            activeAOI={activeAOI}
            activePhases={activePhases}
            activeStages={activeStages}
            active={activeTab === 'clinical-trials'}
            onSelected={() => tabClicked('clinical-trials')}
            fromScientificPillar={fromScientific}
          />
          <Abbreviations
            targetCompound={targetCompound}
            active={activeTab === 'abbreviations'}
            onSelected={() => tabClicked('abbreviations')}
          />
        </div>
      </div>
    </MainContentWrapper>
  );
}

export default TargetCompound;
