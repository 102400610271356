import React, { useState } from 'react';

interface IProps {
  inclusion: string,
  exclusion: string
}

function EligibilityCriteria(props: IProps) {
  const {
    inclusion,
    exclusion,
  } = props;
  const [accordianActive, setAccordianActive] = useState<boolean>(false);

  /**
   * Handle click event on accordion item.
   */
  function handleClick() {
    setAccordianActive(!accordianActive);
  }

  return (
    <div className="collapse-card collapse-card-criteria" data-vv-action="click">
      <button
        type="button"
        className={`card-header header-action ${accordianActive ? 'active' : ''}`}
        style={{ background: 'rgba(197, 215, 45, 0.44)' }}
        onClick={handleClick}
      >
        <h2><strong>Eligibility criteria</strong></h2>
        <em className="collapse-icon" />
      </button>
      <div className={`card-body ${accordianActive ? 'active' : ''}`}>
        <div className="criteria-content-block">
          <div className="row">
            {inclusion && (
              <div className={`col-12 col-md-${exclusion ? '6' : '12'}`}>
                <h3><strong>Inclusion criteria</strong></h3>
                <div className="criteria-info">
                  <p dangerouslySetInnerHTML={{ __html: inclusion }} />
                </div>
              </div>
            )}
            {exclusion && (
              <div className={`col-12 col-md-${inclusion ? '6' : '12'}`}>
                <h3><strong>Exclusion criteria</strong></h3>
                <div className="criteria-info">
                  <p dangerouslySetInnerHTML={{ __html: exclusion }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EligibilityCriteria;
