import _ from 'lodash';
import ITargetCompound from '../interfaces/TargetCompound';
import { romanToArabic } from '../helpers/Number';
import IClinicalTrial from '../interfaces/ClinicalTrial';
import TumourType from './TumourType';
import ITumourType from '../interfaces/TumourType';

export default {
  // Get Target/Compound by tumour type ID and target/compound ID.
  GetById: (tumourTypeId: number, targetCompoundId: number): ITargetCompound | null => {
    const tumourType = TumourType.GetById(tumourTypeId);
    if (!tumourType || !tumourType.field_targets_compounds) {
      return null;
    }

    const tcid = Number(targetCompoundId);

    return tumourType.field_targets_compounds
      .find((compound) => Number(compound.id) === tcid) || null;
  },

  GetGlobalById: (
    tumourTypes: ITumourType[] | null,
    targetCompoundId: number,
  ): ITargetCompound | null => {
    let targetCompound: ITargetCompound | null = null;
    tumourTypes?.forEach((tt) => {
      if (!targetCompound) {
        if (tt && tt.field_targets_compounds) {
          const tcid = Number(targetCompoundId);

          targetCompound = tt.field_targets_compounds
            .find((compound) => Number(compound.id) === tcid) || null;
        }
      }
    });
    return targetCompound;
  },

  // Get target/compound phase range.
  GetPhase: (targetCompound: ITargetCompound): string => {
    let minArabic = 0;
    let maxArabic = 0;
    let minRoman = '';
    let maxRoman = '';
    _.forEach(targetCompound.field_areas_under_investigation, (area) => {
      _.forEach(area.field_clinical_trials, (ct) => {
        if (ct.field_phase) {
          // Convert roman number into arabic
          const arabic = romanToArabic(ct.field_phase.number);
          // Compare arabic numbers and get max and min
          if (!minArabic || arabic < minArabic) {
            minArabic = arabic;
            minRoman = ct.field_phase.number;
          }
          if (!maxArabic || arabic > maxArabic) {
            maxArabic = arabic;
            maxRoman = ct.field_phase.number;
          }
        }
      });
    });

    if (!minRoman && !maxRoman) {
      return '';
    }

    if (minRoman === maxRoman) {
      return minRoman;
    }

    return `${minRoman}-${maxRoman}`;
  },

  // Get target/compound phase array.
  GetPhasesArray: (targetCompound: ITargetCompound): object => {
    let minArabic = 0;
    let maxArabic = 0;
    let minRoman = '';
    let maxRoman = '';
    _.forEach(targetCompound.field_areas_under_investigation, (area) => {
      _.forEach(area.field_clinical_trials, (ct) => {
        if (ct.field_phase) {
          // Convert roman number into arabic
          const arabic = romanToArabic(ct.field_phase.number);
          // Compare arabic numbers and get max and min
          if (!minArabic || arabic < minArabic) {
            minArabic = arabic;
            minRoman = ct.field_phase.number;
          }
          if (!maxArabic || arabic > maxArabic) {
            maxArabic = arabic;
            maxRoman = ct.field_phase.number;
          }
        }
      });
    });

    if (!minRoman && !maxRoman) {
      return {};
    }

    if (minRoman === maxRoman) {
      return { minRoman };
    }

    return { minRoman, maxRoman };
  },

  // Get all clinical trials of target/compound.
  GetClinicalTrials: (
    targetCompound: ITargetCompound,
    areas: number[] = [],
    phase: string[] = [],
    ordering = false,
  ): IClinicalTrial[] => {
    const trialIds: number[] = [];
    const clinicalTrials: IClinicalTrial[] = [];
    const allAreas = areas.length
      ? targetCompound.field_areas_under_investigation
        .filter((area) => areas.indexOf(Number(area.id)) >= 0)
      : targetCompound.field_areas_under_investigation;

    _.forEach(allAreas, (area) => {
      if (area.field_clinical_trials) {
        _.forEach(area.field_clinical_trials, (trial) => {
          if (trialIds.indexOf(trial.id) < 0) {
            if (phase.length === 0 || phase.indexOf(trial.field_phase.number) >= 0) {
              trialIds.push(trial.id);
              clinicalTrials.push(trial);
            }
          }
        });
      }
    });

    if (ordering) {
      clinicalTrials.sort((a, b) => a.label.localeCompare(b.label));
    }

    return clinicalTrials;
  },

  // Get tumour type by target/compound ID.
  GetTumourTypeByTargetCompoundId: (id: number): ITumourType | null => {
    const tumourTypes = TumourType.GetAll();
    const tumourType = tumourTypes.find((type) => type.field_targets_compounds
      .find((targetCompound) => Number(targetCompound.id) === id));

    return tumourType || null;
  },

  // Get phase number.
  GetPhaseNumber: (phase: string): string[] => {
    let minArabic = 0;
    let maxArabic = 0;
    let minRoman = '';
    let maxRoman = '';
    if (phase.includes('/')) {
      const phaseArray = phase.includes('/') ? phase.split('/') : [];
      if (phaseArray) {
        for (let i = 0; i < phaseArray.length; i += 1) {
          const newPhase = phaseArray[i].replace(/[a-z]/g, '');
          const arabic = romanToArabic(newPhase);
          // Compare arabic numbers and get max and min
          if (!minArabic || arabic < minArabic) {
            minArabic = arabic;
            minRoman = newPhase;
          }
          if (!maxArabic || arabic > maxArabic) {
            maxArabic = arabic;
            maxRoman = newPhase;
          }
        }
      }
    } else {
      minRoman = phase.replace(/[a-z]/g, '');
    }
    return [minRoman, maxRoman];
  },
};
