import React, { MouseEvent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import TumourType from '../../../store/TumourType';
import Info from './Partials/Info/Info';
import NotFound from '../NotFound/NotFound';
import getSiteName, { getWindowPathName } from '../../../helpers/Common';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';
import IndicationList from './Partials/IndicationList/IndicationList';

function TumourTypeDetail() {
  const { id } = useParams<{ id: string }>();
  const tumourTypeId = Number(id);
  const tumourType = TumourType.GetById(tumourTypeId);
  // const tumorTargetCompound = TumourType.GetTargetCompounds(tumourTypeId);
  const tumorAreaUnderInvestigation = TumourType.GetAreaUnderInvestigations(tumourTypeId);
  const history = useHistory();

  if (!tumourType) {
    return (<NotFound />);
  }

  const mainStyle = tumourType.field_visual ? { backgroundImage: `url(${tumourType.field_visual})` } : {};

  useEffect(() => {
    document.title = `${tumourType.label} | ${getSiteName()}`;
  }, []);

  /**
   * Handle link click.
   *
   * @param tcid
   */
  function handleLink(event: MouseEvent) {
    event.preventDefault();
    const from = {
      pathname: `/tumour-type/${tumourTypeId}/indications`,
      state: {},
      prevPath: getWindowPathName(),
    };
    history.push(from);
  }

  return (
    <MainContentWrapper wrapperClass="bg-mask-wrapper" wrapperStyle={mainStyle}>
      <div className="inner-container">
        <div className="container">
          <Info tumourType={tumourType} />
          <div className="clear" />
          <div className="select-title-block">
            <div className="title-block title-wrap mb-10">
              <div className="title">Select areas under investigation to view related trials</div>
              {tumorAreaUnderInvestigation.length > 0 && (
                <a
                  href="#"
                  onClick={(event) => handleLink(event)}
                  className="btn btn-border-radius btn-icon"
                  rel="nofollow"
                >
                  {`View all ${tumourType.label} trials`}
                  <em className="az-icon icon-arrow-right-white" />
                </a>
              )}
            </div>
          </div>
          <IndicationList mechanisms={tumorAreaUnderInvestigation} />
        </div>
      </div>
    </MainContentWrapper>
  );
}

export default TumourTypeDetail;
