import React, { useEffect } from 'react';
import FooterDisclaimer from '../FooterDisclaimer/FooterDisclaimer';
import FooterNavigation from '../FooterNavigation/FooterNavigation';
// import { getCookie, setCookie } from '../../../helpers/Cookies';

function Footer() {
  // const [visualContrast, setVisualContrast] = useState(false);

  /**
   * toggle accessibility button.
   */
  /* function changeVisualContrast(contrast: boolean) {
    if (contrast) {
      document.querySelector('body')!.classList.add('is-accessibility');
    } else {
      document.querySelector('body')!.classList.remove('is-accessibility');
    }
    setCookie('vContrast', contrast ? '1' : '0');
    setVisualContrast(contrast);
  } */

  useEffect(() => {
    // const isVisualContrast = getCookie('vContrast');
    // changeVisualContrast(isVisualContrast === '1');
    document.querySelector('body')!.classList.add('is-accessibility');
  }, []);

  return (
    <>
      <footer className="main-footer">
        <div className="container">
          <div className="row footer-content-wrapper">
            <div className="col-12 col-lg-auto footer-info">
              <FooterDisclaimer />
            </div>
            <div className="col-12 col-lg-auto footer-menu">
              <FooterNavigation />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
